import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';

// Начальное состояние
const initialState = {
    jobData: {
        id: null,
        description: "",
        jobParams: [],
    },
};

// Создаём slice
export const jobRequestSlice = createSlice({
    name: "JobRequest",
    initialState,
    reducers: {
        // Экшен для установки данных jobData
        setJobData(state, action) {
            state.jobData = action.payload;
        },
        // Экшен для обновления параметра в jobParams
        updateJobParam(state, action) {
            const { paramId, newValue } = action.payload;
            const param = state.jobData.jobParams.find((p) => p.id === paramId);
            if (param) {
                param.value = newValue; // Immer позволяет безопасно "мутировать" состояние
            }
        },
    },
});

export const { setJobData, updateJobParam } = jobRequestSlice.actions;
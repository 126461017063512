import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {AxiosGetWithoutState} from "../../../axios/AxiosGet";
import {ProcessStatuses} from "./jobsSlice";
import {createSelector} from "reselect";


// Асинхронный thunk для получения данных о файлах приоритетов
export const fetchFrameDataVersion = createAsyncThunk(
    'frameDataVersions/fetchFrameDataVersion',
    async ({ frameDataApiUrl, currentToken }, { rejectWithValue }) => {
        try {
            const { loaded, error, data } = await AxiosGetWithoutState(frameDataApiUrl + '/version', "GET", {}, currentToken);
            if (error) {
                return rejectWithValue(error);
            }
            return data;
        } catch (err) {
            return rejectWithValue('Не удалось получить информацию о сгенерированных данных для фрейма');
        }
    }
);

const frameDataVersionsSlice = createSlice({
    name: 'frameDataVersions',
    initialState: {
        versions: [],
        status: 'idle', // idle | loading | succeeded | failed
        error: null,
    },
    reducers: {
        addVersion: (state, action) => {
            // debugger;
            const version = action.payload;

            const existingVersionInfoIndex = state.versions.findIndex(p => p.id === version.id);
            if (existingVersionInfoIndex === -1) {
                // Если процесса с таким processId нет, добавляем новый объект processInfo в state
                state.versions.push({
                    id: version.id, // ID версии
                    bank: version.bank.toUpperCase(), // Преобразуем название банка в верхний регистр
                    description: version.description || 'Без описания', // Описание версии
                    createdAt: version.createdAt, // Дата создания
                    generatedOnDate: version.generatedOnDate, // Дата генерации
                    operationMode: version.operationMode || 'Не указан', // Режим работы
                    status: version.status || 'Неизвестен', // Статус версии
                    bucketName: version.bucketName || 'Не указано', // Имя бакета
                    processList: version.generationProcessList || []
                });
            } else {
                // Если процесс с таким processId уже существует, обновляем его данные
                state.versions[existingVersionInfoIndex] = { ...state.versions[existingVersionInfoIndex], ...version, status: version.status };
            }

            state.versions.sort((a, b) => b.id - a.id);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFrameDataVersion.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchFrameDataVersion.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.versions = action.payload.sort((a, b) => b.id - a.id);
            })
            .addCase(fetchFrameDataVersion.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const selectGenerationList = state => state.frameDataVersions.versions;

// Выбор списка джобов по id
export const selectGenerationById = createSelector(
    [selectGenerationList, (_, id) => id],
    (generationList, id) => {
        return generationList.find(generation => generation.id === id)
    }
);


export function getColorGenerationStatusesByKeyword(status) {
    const statusDetails = ProcessStatuses.find(s => s.id === status);
    return statusDetails ? statusDetails.color : 'rgb(128, 128, 128)'; // Возвращаем серый цвет, если статус не найден
}

export function translateGenerationStatus(status) {
    const statusDetails = ProcessStatuses.find(s => s.id === status);
    return statusDetails ? statusDetails.name : 'Неизвестный статус';
}

export const { addVersion } = frameDataVersionsSlice.actions;

// Экспортируем slice целиком
export default frameDataVersionsSlice;

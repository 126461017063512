import React, {useEffect, useMemo, useState} from 'react';
import {
    Button,
    Chip,
    Collapse, Dialog, DialogContent, DialogTitle, Grid,
    IconButton,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from '@mui/material';
import PageContainer from '../../components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import TypeSwitchBar from "../../layouts/full/TypeSwitchBar/TypeSwitchBar";
import {IconLayoutDashboard} from "@tabler/icons";
import {IconFile, IconClipboardTypography, IconBuildingBank} from '@tabler/icons-react';
import {useSnackbar} from "notistack";
import {AxiosGet, AxiosGetWithoutState} from "../../axios/AxiosGet";
import {fileServiceApiUrl, jobServiceApiUrl, processServiceApiUrl} from "../../schema/Environment";

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AnalyticsIcon from '@mui/icons-material/Analytics';

import JobLauncherBox from "./JobLauncherBox";
import {useDispatch, useSelector} from "react-redux";
import {groupBy} from "lodash";
import {Banks, getColorBankByKeyword, getNameBankByKeyword, setSelectedTypeId} from "./reducers/bankFilterSlice";
import {useTheme} from "@mui/material/styles";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {setSelectedFileType} from "./reducers/fileTypeFilterSlice";
import {setSelectedFileStatus} from "./reducers/fileStatusFilterSlice";
import {
    addJobList, addProcessInfoByList,
    selectJobsByProcessIds
} from "./reducers/jobsSlice";
// import {createWebSocketConnection} from "../../components/webSocket/WebSocketUtils";
import {
    addFileInfo,
    addFileInfoList,
    cleanFileList, FileStatuses, FileTypes,
    selectFileById,
    selectSortedFilesByDate, translateFileStatus, translateFileType
} from "./reducers/fileSlice";
import UseToken from "../../auth/UseToken";

import {JobContainer} from "./JobsTable";
import {LoaderContent} from "../modal/RowsDataModal";
import {CustomContentForLoadAndErrorWithContent} from "../../components/CustomContentForLoadAndError";
import {
    closeFileUploadStatisticModal,
    openFileUploadStatisticModal
} from "./reducers/modalFileUploadStatisticSlice";
import {getWebSocket} from "./store/webSocket/websocketManager";


function FileManagerView() {
    const {enqueueSnackbar} = useSnackbar();
    const {currentToken, setToken} = UseToken();
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        AxiosGetWithoutState(fileServiceApiUrl + "/file/findAll", "GET", null, currentToken).then(({
                                                                                                       loaded,
                                                                                                       error,
                                                                                                       data
                                                                                                   }) => {

            setLoaded(true);
            if (error) {
                enqueueSnackbar("Не удалось получить файлы: \n" + error, {variant: "error"});
                console.log("Не удалось получить файлы: \n" + error);
            } else {
                dispatch(cleanFileList())
                dispatch(addFileInfoList(data));
            }
        }).catch(error => {
            enqueueSnackbar("Не удалось получить задачи", {variant: "error"});
            console.log("Не удалось получить задачи: \n" + error);
        });
    }, []);

    if (loaded === false) {
        return (
            <PageContainer title="Sample Page" description="this is Sample page">
                <DashboardCard>
                    <Skeleton/>
                </DashboardCard>
            </PageContainer>
        )
    } else {
        return (
            <PageContainer title="Sample Page" description="this is Sample page">
                <BankFilter/>
                <FileTypeFilter/>
                <FileStatusFilter/>
                <UploadFileRow/>
                <FileListView/>
                <UploadPreviousPeriod/>
            </PageContainer>
        );
    }
}

function UploadFileRow() {
    const {enqueueSnackbar} = useSnackbar();
    const [isOpen, setIsOpen] = useState(false);
    const selectedFileTypeFilter = useSelector((state) => state.fileTypeFilter.selectedFileType);

    // Функция для закрытия модального окна
    const handleStatModalClose = () => {
        setIsOpen(false);
    };
    const handleOpen = () => {
        if(selectedFileTypeFilter)
            setIsOpen(true);
        else
            enqueueSnackbar("Выберите тип файла", {variant: "warning"});
    };

    return (
        <>
            <DashboardCard>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item xs={6} alignItems="center">
                        <Typography variant="h6">Повторная загрузка файла</Typography>
                    </Grid>
                    <Grid item xs={6} alignItems="center" style={{ textAlign: 'right' }}>
                        <Button variant="contained" color="primary" onClick={handleOpen}>
                            Указать параметры
                        </Button>
                    </Grid>
                </Grid>
            </DashboardCard>
            <UploadFileModal isOpen={isOpen} onClose={handleStatModalClose}/>
        </>
    );
}


function UploadPreviousPeriod() {
    const {enqueueSnackbar} = useSnackbar();
    const {currentToken, setToken} = UseToken();
    const [endDate, setEndDate] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const date = new Date();
        date.setDate(date.getDate() - 7);
        setEndDate(date);
    }, []);

    function loadFiles(countDays){
        setLoading(true);
        setError(null);
        // debugger
            const startDate = new Date(endDate);
            startDate.setDate(endDate.getDate() - countDays);


            AxiosGetWithoutState(fileServiceApiUrl + "/file/findByDateRange", "GET", {
                startDate: startDate.toISOString().split('T')[0],
                endDate: endDate.toISOString().split('T')[0]
            }, currentToken).then(({loaded, error, data}) => {
                if(error){
                    enqueueSnackbar("Не удалось получить статусы процессов: \n" + error, {variant: "error"});
                    console.log("Не удалось получить статусы процессов: \n" + error);
                }
                else {
                    dispatch(addFileInfoList(data));
                    setEndDate(startDate);
                }
            }).catch(error => {
                enqueueSnackbar("Не удалось получить статусы процессов", {variant: "error"});
                console.log("Не удалось получить статусы процессов: \n" + error);
            });
        setLoading(false);
    };

    return (
        <>
            <DashboardCard>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item xs={6} alignItems="center">
                        <Typography variant="h6">Добавить данные</Typography>
                    </Grid>
                    <Grid item xs={6} alignItems="center" style={{ textAlign: 'right' }}>
                        <Grid container spacing={2}  justifyContent="flex-end">
                            <Grid item alignItems="center">
                                <Button variant="contained" color="primary" onClick={() => loadFiles(7)}>
                                    За неделю
                                </Button>
                            </Grid>
                            <Grid item alignItems="center">
                                <Button variant="contained" color="primary" onClick={() => loadFiles(30)}>
                                    За месяц
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DashboardCard>
        </>
    );
}

function UploadFileModal({isOpen, onClose}) {
    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth={"70%"}>
            <DialogTitle>{"Параметры загрузки файла"}</DialogTitle>
            <DialogContent sx={{p: '1rem !important'}}>
                <BridgeJobLauncherView/>
            </DialogContent>
        </Dialog>
    )
}

function BridgeJobLauncherView() {
    const {enqueueSnackbar} = useSnackbar();
    const {loaded, error, data} = AxiosGet(jobServiceApiUrl + "/job/find/all", "GET", null, enqueueSnackbar);
    const [selectedJob, setSelectedJob] = useState(null);
    const selectedFileTypeFilter = useSelector((state) => state.fileTypeFilter.selectedFileType);


    const paramValues = FileTypes.map(({name, id}) => ({
        value: name,
        key: id
    }));

    // debugger;
    useEffect(() => {
        if (data) {
            let fileTypeFilter = selectedFileTypeFilter;
            if(!fileTypeFilter)
                fileTypeFilter = "TRANSACTIONS";
            const foundJob = data.find(job => job.launchType === fileTypeFilter);
            if (foundJob) {
                // Создаем новый JobParam
                const newJobParam = {
                    jobId: foundJob.id,
                    name: "LAUNCH_TYPE",
                    description: "Укажите тип файла чтобы система смогла понять как именно его нужно загрузить", // Пример описания
                    jobParamType: "STRING_FROM_VALUE_LIST",
                    required: true,
                    jobParamValues: paramValues
                };

                // Добавляем новый JobParam в массив jobParams найденной работы
                const updatedJobParams = foundJob.jobParams.concat(newJobParam);

                // Создаем копию найденной работы с обновленным массивом jobParams
                const updatedJob = {
                    ...foundJob,
                    jobParams: updatedJobParams
                };

                setSelectedJob(updatedJob);
            } else {
                setSelectedJob(null); // или другое действие, если работа не найдена
            }
        }
    }, [data, selectedFileTypeFilter]);

    if (data) {
        return (
            <>
                {selectedJob ?
                    <>
                        <JobLauncherBox job={selectedJob} url={fileServiceApiUrl + "/file/createUploadRequest"}/>
                    </>
                    : <></>
                }
            </>
        );
    } else{
        return (
            <PageContainer title="Sample Page" description="this is Sample page">
                <DashboardCard>
                    <Skeleton/>
                </DashboardCard>
            </PageContainer>
        )
    }

    return (
        <></>
    )
}

function FileListView() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {isOpen, url} = useSelector((state) => state.modalFileUploadStatistic);

    // Функция для закрытия модального окна
    const handleStatModalClose = () => {
        dispatch(closeFileUploadStatisticModal());
    };

    let filteredFileList = FilesByFilters();

    return (
        <>
            <TableContainer component={Paper} style={{paddingBottom: "1rem", marginBottom: "1rem", maxWidth: "100%"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Банк</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Дата создания</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800,
                                maxWidth: "15rem"
                            }}>Имя файла</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Тип файла</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Статус</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Количество строк</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Количество ошибок</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Размер</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Скачать</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Статистика</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>-</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredFileList.map((file, index) => (
                            <FileRow key={file.id} id={file.id} index={index}/>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
            {isOpen && (
                <UploadStatisticDataModal
                    url={url}
                    isOpen={isOpen}
                    onClose={handleStatModalClose}
                />
            )}
        </>
    );
}

const FileRow = React.memo(({id, index}) => {
    const dispatch = useDispatch();
    const file = useSelector(state => selectFileById(state, id));
    const {enqueueSnackbar} = useSnackbar();
    const [open, setOpen] = React.useState({});
    const {currentToken, setToken} = UseToken();
    const processIds = useMemo(() => file.fileProcessList.map(item => item.processId), [file.fileProcessList]);
    const jobProcessIds = useSelector(state => selectJobsByProcessIds(state, processIds));

    const urlForStatData = fileServiceApiUrl + "/statistic/find/byFileId?fileId=";

    // Функция для открытия модального окна
    const handleStatModalOpen = (fileId) => {
        dispatch(openFileUploadStatisticModal({url: urlForStatData + fileId}));
    };

    const showFileProcesses = (id) => {
        if (jobProcessIds && jobProcessIds.length === 0)
            loadJobsForFile();
        setOpen({...open, [id]: !open[id]});
    };

    // useEffect(() => {
    //     if(((file.fileType !== "NONE_REGULAR" && file.status !== "UPLOADED_TO_SYSTEM" && file.status !== "ERROR")))
    //         loadJobsForFile();
    // }, [id]);

    function loadJobsForFile() {
        if (file) {
            const processIdListQuery = file.fileProcessList.map(item => encodeURIComponent(item.processId)).join(',');
            AxiosGetWithoutState(jobServiceApiUrl + "/jobRequest/find/byProcessIds", "GET", {processIds: processIdListQuery}, currentToken)
                .then(({loaded, error, data}) => {
                    if (error) {
                        enqueueSnackbar(error, {variant: "error"});
                        console.log("Не удалось получить задачи по загрузке для файла: \n" + error);
                    } else {
                        // debugger;
                        dispatch(addJobList(data));
                    }
                }).catch(error => {
                enqueueSnackbar("Не удалось получить задачи", {variant: "error"});
                console.log("Не удалось получить задачи: \n" + error);
            });
        }
    }

    useEffect(() => {
        if (file && ((file.fileType !== "NONE_REGULAR" && file.status !== "UPLOADED_TO_SYSTEM" && file.status !== "ERROR") || (file.fileType === "NONE_REGULAR" && file.status !== "UPLOADED_TO_STORAGE"))) {

            const topic = '/fileInfo/' + file.id;
            // Функция обратного вызова для обработки сообщений
            const onMessage = (updatedFileInfo) => {
                console.log("updatedFileInfo: ", updatedFileInfo);
                dispatch(addFileInfo(updatedFileInfo));
            };

            const webSocket = getWebSocket('fileService');
            // debugger;
            if (webSocket) {
                webSocket.subscribe(topic, onMessage);
            }
        }
    }, [id]);

    const downloadFile = async (fileId) => {
        const reqHeaders = new Headers({
            'token': currentToken, // Замените currentToken на актуальное значение вашего токена
        });
        try {
            const response = await fetch(fileServiceApiUrl + `/file/download/${fileId}`, {
                method: 'GET',
                headers: reqHeaders,
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = response.headers.get('Content-Disposition').split('filename=')[1].replaceAll('"', '');
            document.body.appendChild(a); // Append the anchor tag to the document
            a.click(); // Simulate a click on the anchor tag
            document.body.removeChild(a); // Remove the anchor tag from the document
            window.URL.revokeObjectURL(url); // Clean up the URL object
        } catch (error) {
            enqueueSnackbar("Не удалось скачать файл", {variant: "error"});
        }
    };

    // function FileTypeChip({ file }) {
    //     const color = getColorFileByKeyword(file.fileType);
    //     return <Chip label={<Typography variant="uptitleBold3">{translateFileType(file.fileType)}</Typography>} style={{ border: "2px solid", borderColor: color, backgroundColor: "unset" }}/>;
    // }

    function FileStatusChip({file}) {
        const color = getColorFileStatusByKeyword(file.status);
        return <Chip label={<Typography variant="uptitleBold3">{translateFileStatus(file.status)}</Typography>}
                     style={{border: "2px solid", borderColor: color, backgroundColor: "unset"}}/>;
    }

    if (file) {
        return (
            <React.Fragment key={id}>
                <TableRow key={index}>
                    <TableCell align="center"><Typography variant="uptitleBold3">{getNameBankByKeyword(file.bank)}</Typography></TableCell>
                    <TableCell align="center"><Typography variant="uptitleBold3">{formatDate(file.created)}</Typography></TableCell>
                    <TableCell align="center" sx={{maxWidth: '12rem', overflow: 'auto'}}><Typography
                        variant="uptitleBold3">{file.name}</Typography></TableCell>
                    <TableCell align="center" sx={{maxWidth: '8rem', overflow: 'auto'}}><Typography variant="uptitleBold3"
                                                          sx={{borderBottom: "2px solid " + getColorFileByKeyword(file.fileType)}}>{translateFileType(file.fileType)}</Typography></TableCell>
                    <TableCell align="center" sx={{maxWidth: '12rem', overflow: 'auto'}}><FileStatusChip file={file}/></TableCell>
                    <TableCell align="center" sx={{maxWidth: '2rem', overflow: 'auto'}}><Typography
                        variant="uptitleBold3">{file.countRows}</Typography></TableCell>
                    <TableCell align="center"  sx={{maxWidth: '2rem', overflow: 'auto'}}><Typography
                        variant="uptitleBold3">{file.countErrors}</Typography></TableCell>
                    <TableCell align="center"  sx={{maxWidth: '2rem', overflow: 'auto'}}><Typography variant="uptitleBold3">{file.size}</Typography></TableCell>
                    <TableCell align="center"><CloudDownloadIcon onClick={() => downloadFile(file.id)}
                                                                 style={{cursor: 'pointer'}}/></TableCell>
                    <TableCell align="center"><AnalyticsIcon onClick={() => handleStatModalOpen(file.id)}
                                                             style={{cursor: 'pointer'}}/></TableCell>
                    <TableCell align="center">
                        <IconButton size="small" onClick={() => showFileProcesses(id)}>
                            {open[id] ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                        <Collapse in={open[id]} timeout="auto" unmountOnExit>
                            <Table aria-label="purchases" className="nested-table">
                                <TableBody
                                    style={{backgroundColor: "#00c6ff17", border: "1px solid #c2c2c2"}}>
                                    <TableRow>
                                        <TableCell style={{border: "1px solid #c2c2c2"}} align={"center"}
                                                   sx={{color: "#2e2e2e", fontWeight: 800}}>ID</TableCell>
                                        <TableCell style={{border: "1px solid #c2c2c2"}}
                                                   align={"center"} sx={{
                                            color: "#2e2e2e",
                                            fontWeight: 800
                                        }}>Параметры</TableCell>
                                        <TableCell style={{border: "1px solid #c2c2c2"}}
                                                   align={"center"} sx={{
                                            color: "#2e2e2e",
                                            fontWeight: 800
                                        }}>Статус</TableCell>
                                        <TableCell style={{border: "1px solid #c2c2c2"}}
                                                   align={"center"}
                                                   sx={{color: "#2e2e2e", fontWeight: 800}}>Время до
                                            окончания</TableCell>
                                        <TableCell style={{border: "1px solid #c2c2c2"}}
                                                   align={"center"} sx={{
                                            color: "#2e2e2e",
                                            fontWeight: 800
                                        }}>Инфо</TableCell>
                                    </TableRow>
                                    {jobProcessIds?.map((processId) => (
                                        <JobContainer key={processId} processId={processId}/>
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }
})


function UploadStatisticDataModal({url, title, isOpen, onClose}) {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();
    const {loaded, data, error} = AxiosGet(url, "GET", null, enqueueSnackbar);

    let res = CustomContentForLoadAndErrorWithContent(loaded, error, LoaderContent(isOpen, onClose, title));
    if (res !== null)
        return res;
    else {
        // debugger;
        return (
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>{"Статистка по загрузке файла"}</DialogTitle>
                <DialogContent sx={{p: '1rem !important'}}>
                    {loaded ? (
                        data.map((stat, index) => (
                            <>
                                <TableContainer component={Paper} style={{paddingBottom: "1rem"}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align={"center"} sx={{
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: theme.palette.primary.light,
                                                    fontWeight: 800
                                                }}>Id процесса</TableCell>
                                                <TableCell align={"center"} sx={{
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: theme.palette.primary.light,
                                                    fontWeight: 800
                                                }}>Количество строк</TableCell>
                                                <TableCell align={"center"} sx={{
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: theme.palette.primary.light,
                                                    fontWeight: 800
                                                }}>Количество строк с ошибками</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {stat ?
                                                <CommonUploadStatisticData stat={stat} index={index}/>
                                                : <></>
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TableContainer component={Paper} style={{paddingBottom: "1rem"}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align={"center"} sx={{
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: theme.palette.primary.light,
                                                    fontWeight: 800
                                                }}>Ключ</TableCell>
                                                <TableCell align={"center"} sx={{
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: theme.palette.primary.light,
                                                    fontWeight: 800
                                                }}>Количество записей для сохранения</TableCell>
                                                <TableCell align={"center"} sx={{
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: theme.palette.primary.light,
                                                    fontWeight: 800
                                                }}>Количество существующих записей</TableCell>
                                                <TableCell align={"center"} sx={{
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: theme.palette.primary.light,
                                                    fontWeight: 800
                                                }}>Количество сохраненных записей</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {stat?.statisticDataList?.map((stat, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align={"center"}>{stat.name}</TableCell>
                                                    <TableCell align={"center"}>{stat.countRows}</TableCell>
                                                    <TableCell align={"center"}>{stat.existedRows}</TableCell>
                                                    <TableCell align={"center"}>{stat.savedRows}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        ))
                    ) : (
                        <></>
                    )}
                </DialogContent>
                {/*<DialogActions>*/}
                {/*    <Button onClick={onClose}>Close</Button>*/}
                {/*</DialogActions>*/}
            </Dialog>
        );
    }
}

function CommonUploadStatisticData({stat, index}) {
    // debugger;
    return (
        <TableRow key={index}>
            <TableCell align={"center"}>{stat.processId}</TableCell>
            <TableCell align={"center"}>{stat.countRows}</TableCell>
            <TableCell align={"center"}>{stat.errorRows}</TableCell>
        </TableRow>
    );
}


function BankFilter() {
    const dispatch = useDispatch();
    const fileList = useSelector((state) => state.files);
    const [selectedTypeId, setSelectedTypeIdLocal] = useState(null);

    let groupsByBank;
    try {
        groupsByBank = groupBy(fileList, "bank");
    }catch (e){

    }

    let names = [];

    if(groupsByBank && Object.entries(groupsByBank).length > 0)
        names = Object.entries(groupsByBank).map(g => g[0]);

    const handleSelect = (id) => {

        setSelectedTypeIdLocal(id);
        dispatch(setSelectedTypeId(id));
    };

    let banksList = Banks.filter(b => names.includes(b.id)).sort((a, b) => a.name.localeCompare(b.name));


    let bankItems = Array.from(banksList, (item) => ({
        id: item.id,
        title: item.name,
        icon: () => <IconBuildingBank stroke={2} color={item.id ? getColorBankByKeyword(item.id) : 'grey'}/>
    }));

    if(bankItems.length > 0)
        return (
            <TypeSwitchBar title={'Фильтр. Банк'} items={bankItems} valueSetter={handleSelect} selectedId={selectedTypeId} gridSize={"SMALL"} />
        );
    else
        return <></>;
}

function getColorFileByKeyword(keyword) {
    if (keyword) {
        const fileType = FileTypes.find(type => keyword.includes(type.id));
        return fileType ? fileType.color : 'grey'; // Возвращаем цвет найденного типа или серый, если ничего не найдено
    }
    return 'grey'; // Возвращаем серый цвет, если ключевое слово отсутствует
}

export function getColorFileStatusByKeyword(status) {
    const statusDetails = FileStatuses.find(s => s.id === status);
    return statusDetails ? statusDetails.color : 'rgb(128, 128, 128)'; // Возвращаем серый цвет, если статус не найден
}

function FileTypeFilter() {
    const dispatch = useDispatch();
    const [selectedFileType, setSelectedFileTypeLocal] = useState(null);
    const selectedBankFilter = useSelector((state) => state.bankFilter.selectedTypeId);
    const fileList = useSelector(selectSortedFilesByDate);


    let filteredFileList = fileList;
    if (selectedBankFilter) {
        filteredFileList = fileList.filter(j => j.bank === selectedBankFilter);
    }


    const handleSelect = (id) => {
        setSelectedFileTypeLocal(id);
        dispatch(setSelectedFileType(id));
    };

    let typesForFiles = FileTypes.filter(s => filteredFileList.map(f => f.fileType).includes(s.id));

// Генерация компонентов иконок с заданным цветом
    let items = typesForFiles.map(item => ({
        ...item,
        icon: () => <IconFile stroke={2} color={item.id ? getColorFileByKeyword(item.id) : 'grey'}/>
    }));


    // debugger;
    let fileTypeItems = Array.from(items, (item) => ({
        id: item.id,
        title: item.name,
        icon: item.icon
    }));

    if (fileTypeItems.length > 0)
        return (
            <TypeSwitchBar title={'Фильтр. Тип'} items={fileTypeItems} valueSetter={handleSelect}
                           selectedId={selectedFileType} gridSize={"SMALL"}/>
        );
    else
        return <></>;
}

function FilesByFilters() {
    const selectedBankFilter = useSelector((state) => state.bankFilter.selectedTypeId);
    const selectedFileTypeFilter = useSelector((state) => state.fileTypeFilter.selectedFileType);
    const selectedFileStatusFilter = useSelector((state) => state.fileStatusFilter.selectedFileStatus);
    const fileList = useSelector(selectSortedFilesByDate);


    let filteredFileList = fileList;
    if (selectedBankFilter) {
        filteredFileList = fileList.filter(j => j.bank === selectedBankFilter);
    }

    if (selectedFileTypeFilter) {
        if (filteredFileList.filter(j => j.fileType === selectedFileTypeFilter).length > 0)
            filteredFileList = filteredFileList.filter(j => j.fileType === selectedFileTypeFilter);
    }

    if (selectedFileStatusFilter) {
        if (filteredFileList.filter(j => j.status === selectedFileStatusFilter).length > 0)
            filteredFileList = filteredFileList.filter(j => j.status === selectedFileStatusFilter);
    }

    return filteredFileList;

}

function FileStatusFilter() {
    const dispatch = useDispatch();
    const [selectedStatus, setSelectedStatusLocal] = useState(null);
    const selectedBankFilter = useSelector((state) => state.bankFilter.selectedTypeId);
    const selectedFileTypeFilter = useSelector((state) => state.fileTypeFilter.selectedFileType);
    const fileList = useSelector(selectSortedFilesByDate);


    let filteredFileList = fileList;
    if (selectedBankFilter) {
        filteredFileList = fileList.filter(j => j.bank === selectedBankFilter);
    }

    if (selectedFileTypeFilter) {
        if (filteredFileList.filter(j => j.fileType === selectedFileTypeFilter).length > 0)
            filteredFileList = filteredFileList.filter(j => j.fileType === selectedFileTypeFilter);
    }


    const handleSelect = (id) => {
        setSelectedStatusLocal(id);
        dispatch(setSelectedFileStatus(id));
    };

    let statusesForFiles = FileStatuses.filter(s => filteredFileList.map(f => f.status).includes(s.id));

    let items = statusesForFiles.map(item => ({
        ...item,
        icon: () => <IconClipboardTypography stroke={2} color={item.id ? getColorFileStatusByKeyword(item.id) : 'grey'}/>
    }));

    // debugger;
    let fileStatusItems = Array.from(items, (item) => ({
        id: item.id,
        title: item.name,
        icon: item.icon
    }));

    if (fileStatusItems.length > 0)
        return (
            <TypeSwitchBar title={'Фильтр. Статус'} items={fileStatusItems} valueSetter={handleSelect}
                           selectedId={selectedStatus} gridSize={"MIDDLE"}/>
        );
    else
        return <></>;
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


export default FileManagerView;

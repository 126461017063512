import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    Box,
    Button, Chip, Collapse, Dialog, DialogContent, DialogTitle, FormControl,
    Grid, IconButton,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField, Typography
} from '@mui/material';
import PageContainer from '../../components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import TypeSwitchBar from "../../layouts/full/TypeSwitchBar/TypeSwitchBar";
import {IconLayoutDashboard, IconSettings} from "@tabler/icons";
import {useSnackbar} from "notistack";
import {AxiosGet, AxiosGetWithoutState} from "../../axios/AxiosGet";
import {
    billingServiceApiUrl,
    frameDataApiUrl, jobServiceApiUrl, processServiceApiUrl
} from "../../schema/Environment";

import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "@mui/material/styles";
import UseToken from "../../auth/UseToken";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {addJobList, addProcessInfoByList, selectJobByProcessId, selectJobsByProcessIds} from "./reducers/jobsSlice";
import {JobContainer} from "./JobsTable";
import axios from "axios";
import {AxiosPostFile, AxiosPostWithoutState, AxiosPutFile, AxiosPutWithoutState} from "../../axios/AxiosPost";
import {StyledTableHeaderCell, StyledTableCell} from "../../components/table/StyledTableComponents";
import {addBillingInfo, addBillingInfoList} from "./reducers/billingSlice";
import JobLauncherBox from "./JobLauncherBox";
import {Banks, getColorBankByKeyword, getNameBankByKeyword} from "./reducers/bankFilterSlice";
import {fetchPriorityFiles,  selectTransformedFilesAsParams} from "./reducers/priorityFileSlice";
import {
    addVersion,
    fetchFrameDataVersion,
    getColorGenerationStatusesByKeyword, selectGenerationById,
    translateGenerationStatus
} from "./reducers/frameDataVersionSlice";
import {IconBuildingBank} from "@tabler/icons-react";
import {selectFileById, translateFileStatus} from "./reducers/fileSlice";
import {getColorFileStatusByKeyword} from "./FileManagerView";
import {getWebSocket} from "./store/webSocket/websocketManager";

import RefreshIcon from '@mui/icons-material/Refresh';
import {SelectField} from "../../components/inputs/SelectField";


const customButtonStyle = {
    height: '30px', // Настройте высоту по необходимости
    // display: 'flex',
    // alignItems: 'center', // Выровнять текст по центру по вертикали
    padding: '0 14px', // Настройте отступы по необходимости
};

const customTextFieldStyle = {
    '& .MuiInputBase-root': {
        height: '30px', // Настройте высоту по необходимости
        display: 'flex',
        alignItems: 'center', // Выровнять текст по центру по вертикали
    },
    '& .MuiOutlinedInput-input': {
        padding: '0 14px', // Настройте отступы по необходимости
    },
    '& .MuiInputLabel-root': {
        top: '50%',
        left: '14px', // Отступ от левого края
        transform: 'translateY(-50%)', // Выровнять подсказку по центру по вертикали
        transition: 'all 0.2s ease', // Добавить анимацию для плавного перемещения
    },
    '& .MuiInputLabel-shrink': {
        top: 0,
        transform: 'translateY(-100%)', // Переместить подсказку наверх при вводе текста
        backgroundColor: 'inherit', // Цвет фона для перекрытия линии
        padding: '0 4px', // Отступы для фона
    },
};

const banks = [
    {
        id: "gpb",
        title: 'Газпром',
        icon: IconLayoutDashboard
    },
    {
        id: "cup",
        title: 'Цупис',
        icon: IconLayoutDashboard
    },
    {
        id: "mts",
        title: 'МТС',
        icon: IconLayoutDashboard
    },
    {
        id: "otp",
        title: 'ОТП Банк',
        icon: IconLayoutDashboard
    },
];

const findUrlById = (id) => {
    const bank = banks.find(bank => bank.id === id);
    return bank ? bank.url : null;
};


export const GenerationStatuses = [
    { name: "Создана", id: "CREATED", color: 'rgb(196,188,27)', count: 10 },
    { name: "Генерируется", id: "GENERATION", color: 'rgb(0, 0, 255)', count: 20 },
    { name: "Завершена", id: "DONE", color: 'rgb(51,135,0)', count: 30 },
    { name: "Ошибка", id: "ERROR", color: 'rgb(163,0,0)', count: 40 }
];

const getStatusNameById = (id) => {
    const status = GenerationStatuses.find(status => status.id === id);
    return status ? status.name : null;
};


function FrameDataGenerationView () {
    const [selectedBank, setSelectedBank] = useState(null);

    const handleSelectBank = (id) => {
        setSelectedBank(id);
    };

    let banksList = Banks.sort((a, b) => a.name.localeCompare(b.name));
    let bankItems = Array.from(banksList, (item) => ({
        id: item.id,
        title: item.name,
        icon: () => <IconBuildingBank stroke={2} color={item.id ? getColorBankByKeyword(item.id) : 'grey'}/>
    }));


    return (
        <>
            {/*<DashboardCard title="Внимание">*/}
            {/*    <Box sx={{marginBottom: "1rem"}}>*/}
            {/*        <Typography variant="uptitleBold2" gutterBottom>*/}
            {/*            Данная страница находится в разработке*/}
            {/*        </Typography>*/}
            {/*    </Box>*/}
            {/*</DashboardCard>*/}
            <UploadPriorityFile/>

            <CreateFrameGenerationJob/>

            <PageContainer title="Sample Page" description="this is Sample page">
                <TypeSwitchBar title={'Фильтр. Банк'} items={bankItems} valueSetter={handleSelectBank} selectedId={selectedBank} gridSize={"SMALL"}/>
            </PageContainer>

            <GeneratedDataView selectedBank={selectedBank}/>

        </>
    );
}


function CreateFrameGenerationJob(){
    const { enqueueSnackbar } = useSnackbar();
    const {currentToken, setToken} = UseToken();
    const dispatch = useDispatch();
    const transformedFiles = useSelector(selectTransformedFilesAsParams);
    // debugger;
    const banksParams = Banks.map(bank => ({
        key: bank.id,
        value: bank.name
    }));

    const FrameGenerationJob = useMemo(() => ({
        id: 0,
        description: "Введите параметры для запуска генерации файлов для фреймов",
        jobParams: [
            {
                id: 1,
                name: "bank",
                description: "Банк",
                jobParamType: "STRING_FROM_VALUE_LIST",
                required: true,
                jobParamValues: banksParams
            },
            {
                id: 2,
                name: "generatedOnDate",
                description: "Генерация на дату",
                jobParamType: "DATE",
                required: true,
                jobParamValues: [
                ]
            },
            {
                id: 3,
                name: "rtmmIds",
                description: "Номера кампания",
                jobParamType: "STRING",
                required: true,
                jobParamValues: [
                ]
            },
            {
                id: 4,
                name: "operationMode",
                description: "Тип генерации",
                jobParamType: "STRING_FROM_VALUE_LIST",
                required: true,
                jobParamValues: [
                    { value: "Все файлы", key: "all" },
                    { value: "Операции", key: "operations" },
                    { value: "Медиа файлы", key: "media" }
                ]
            },
            {
                id: 5,
                name: "description",
                description: "Описание генерации для отчета",
                jobParamType: "STRING",
                required: true,
                jobParamValues: []
            },
            {
                id: 6,
                name: "priorityFileId",
                description: "Файл приоритетов",
                jobParamType: "STRING_FROM_VALUE_LIST",
                required: true,
                jobParamValues: [...transformedFiles].sort((a, b) => b.key - a.key),
                paramsSorted: true
            }

        ]
    }), [transformedFiles]);

    const handleClick = useCallback((jobData) => {
        // Собираем обязательные параметры, которые не входят в группы
        const missingParams = jobData.jobParams
            .filter(item => item.required && !["generatedOnDate", "rtmmIds"].includes(item.name))
            .filter(item => !item.value || item.value === "null" || item.value === "undefined");

        // Проверяем, есть ли значения в группе параметров
        const isGroupValid = jobData.jobParams.some(item =>
            ["generatedOnDate", "rtmmIds"].includes(item.name) &&
            item.value && item.value !== "null" && item.value !== "undefined"
        );

        // Если есть отсутствующие параметры и группа тоже не валидна
        if (missingParams.length > 0 || !isGroupValid) {
            const missingParamDescriptions = missingParams.map(param => param.description);

            if (!isGroupValid) {
                missingParamDescriptions.push("Генерация на дату или Номера кампаний (хотя бы одно из них)");
            }

            const missingParamNames = missingParamDescriptions.join(", ");
            console.error(`Ошибка: отсутствуют значения для обязательных параметров: ${missingParamNames}`);
            enqueueSnackbar(`Отсутствуют значения для обязательных параметров: ${missingParamNames}`, { variant: "error" });
        } else {
            // Если все обязательные параметры переданы, продолжаем обработку
            const launchParamMap = {};
            jobData.jobParams
                .filter(item => item.value !== null && item.value !== "null" && item.value !== undefined && item.value !== "undefined" &&  item.value.toString().length > 0)
                .forEach(obj => {
                    launchParamMap[obj.name] = obj.value;
                });

            // Дальнейшая обработка launchParamMap
            AxiosPostWithoutState(frameDataApiUrl + "/generate",null, launchParamMap,  currentToken).then(({loaded, error, data}) => {

                if(error){
                    enqueueSnackbar(error, {variant: "error"});
                }
                else {
                    enqueueSnackbar("Задача создана", {variant: "success"});
                    dispatch(addVersion(data));
                }
            }).catch(error => {
                enqueueSnackbar("Непредвиденная ошибка при запуске генерации", {variant: "error"});
            });
        }
    }, []);
    return(
        <JobLauncherBox job={FrameGenerationJob} customHandleClick={handleClick}/>
    )
}



function UploadPriorityFile() {
    const [file, setFile] = useState(null);
    const [description, setDescription] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const {currentToken, setToken} = UseToken();

    const dispatch = useDispatch();
    const { files, status, error } = useSelector((state) => state.priorityFiles);

    useEffect(() => {
        dispatch(fetchPriorityFiles({ frameDataApiUrl, currentToken }));
    }, [dispatch, frameDataApiUrl, currentToken]);


    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleFileUpload = async () => {
        if (!file) {
            alert('Пожалуйста, выберите файл для загрузки.');
            return;
        }

        if (!description) {
            alert('Пожалуйста, введите описание.');
            return;
        }

        const formData = new FormData();
        formData.append('multipartFile', file);
        formData.append('description', description);

        try {

            const { loaded, error, data } = await AxiosPostFile(frameDataApiUrl + '/priority_file', formData, currentToken);

            // debugger;
            if (error) {
                enqueueSnackbar(error, { variant: 'error' });
            } else {
                enqueueSnackbar('Файл успешно загружен', { variant: 'success' });
            }
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Непредвиденная ошибка при загрузке файла', { variant: 'error' });
        }
    };

    const handleSetActive = async (id) => {

        AxiosPutWithoutState(frameDataApiUrl + '/priority_file/active/' + id,null, null,  currentToken).then(({loaded, error, data}) => {
            if(error){
                enqueueSnackbar(error, {variant: "error"});
            }
            else {
                enqueueSnackbar("Сохранено", {variant: "success"});
                dispatch(fetchPriorityFiles({ frameDataApiUrl, currentToken }));
            }
        }).catch(error => {
            console.log(error);
            enqueueSnackbar("Непредвиденная ошибка", {variant: "error"});
        });
    };

    return (
        <DashboardCard>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h6">Загрузить приоритеты оферов</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box>
                        <TextField
                            label="Описание"
                            variant="outlined"
                            value={description}
                            onChange={handleDescriptionChange}
                            fullWidth
                            sx={{ ...customTextFieldStyle, marginBottom: '10px' }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Box>
                        <input
                            accept=".txt,.csv"
                            style={{ display: 'none' }}
                            id="upload-file"
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="upload-file">
                            <Button variant="contained" component="span" sx={customButtonStyle}>
                                Выберите файл
                            </Button>
                        </label>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFileUpload}
                            disabled={!file}
                            style={{ marginLeft: '10px' }}
                            sx={customButtonStyle}
                        >
                            Загрузить
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ maxHeight: 200 }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeaderCell>ID</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>Описание</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>Дата создания</StyledTableHeaderCell>
                                    {/*<StyledTableHeaderCell>Активный</StyledTableHeaderCell>*/}
                                    {/*<StyledTableHeaderCell>Действие</StyledTableHeaderCell>*/}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {files.map((file) => (
                                    <TableRow key={file.id}>
                                        <StyledTableCell>{file.id}</StyledTableCell>
                                        <StyledTableCell>{file.description}</StyledTableCell>
                                        <StyledTableCell>{new Date(file.createdAt).toLocaleString()}</StyledTableCell>
                                        {/*<StyledTableCell>{file.isActive ? 'Да' : 'Нет'}</StyledTableCell>*/}
                                        {/*<StyledTableCell>*/}
                                        {/*    <Button*/}
                                        {/*        variant="contained"*/}
                                        {/*        color="primary"*/}
                                        {/*        onClick={() => handleSetActive(file.id)}*/}
                                        {/*        disabled={file.isActive}*/}
                                        {/*    >*/}
                                        {/*        Сделать активным*/}
                                        {/*    </Button>*/}

                                        {/*</StyledTableCell>*/}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </DashboardCard>
    );
}


function GeneratedDataView({selectedBank}) {
    const theme = useTheme();
    const url = frameDataApiUrl + "/version";
    const { enqueueSnackbar } = useSnackbar();
    const {currentToken, setToken} = UseToken();

    const dispatch = useDispatch();
    const { versions, status, error } = useSelector((state) => state.frameDataVersions);

    useEffect(() => {
        dispatch(fetchFrameDataVersion({ frameDataApiUrl, currentToken }));
    }, [dispatch, frameDataApiUrl, currentToken]);

    useEffect(() => {
    }, [selectedBank]);

    if(status === "loading"){
        return (
            <PageContainer title="Sample Page" description="this is Sample page">
                <DashboardCard>
                    <Skeleton />
                </DashboardCard>
            </PageContainer>
        )
    }
    else if (status === "succeeded") {
        // debugger;
        return (
            <>
                <TableContainer component={Paper} style={{paddingBottom: "1rem"}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableHeaderCell>Банк</StyledTableHeaderCell>
                                <StyledTableHeaderCell>Описание</StyledTableHeaderCell>
                                <StyledTableHeaderCell>Дата генерации</StyledTableHeaderCell>
                                <StyledTableHeaderCell>На дату</StyledTableHeaderCell>
                                <StyledTableHeaderCell>Тип</StyledTableHeaderCell>
                                <StyledTableHeaderCell>Инициатор</StyledTableHeaderCell>
                                <StyledTableHeaderCell>Статус</StyledTableHeaderCell>
                                <StyledTableHeaderCell>Управление</StyledTableHeaderCell>
                                <StyledTableHeaderCell>-</StyledTableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                {versions.filter(v => selectedBank ? v.bank === selectedBank.toLowerCase() : v).map((genData, index) => (
                                    <SingleGenData key={index} id={genData.id}/>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }
}

const SingleGenData = React.memo(({id}) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const generation = useSelector(state => selectGenerationById(state, id));
    const {currentToken, setToken} = UseToken();
    const [modalOpen, setModalOpen] = useState(false);
    const [jobDetailsOpen, setJobDetailsOpen] = React.useState({});

    const processIds = useMemo(() => generation.processIdList, [generation.processIdList]);
    const jobProcessIds = useSelector(state => selectJobsByProcessIds(state, processIds));

    useEffect(() => {
        if(generation && (generation.status !== "COMPLETED" && generation.status !== "ERROR")) {

            const topic = '/frameDataGenerationInfo/' + generation.id;

            const onMessage = (updatedFrameGenerationInfo) => {
                console.log("updatedFrameGenerationInfo: ", updatedFrameGenerationInfo);
                dispatch(addVersion(updatedFrameGenerationInfo));
            };

            const webSocket = getWebSocket('frameDataGenerationService');

            if (webSocket) {
                webSocket.subscribe(topic, onMessage);
            }
        }
    }, [id]);


    function GenerationStatusChip() {
        const color = getColorGenerationStatusesByKeyword(generation.status.toUpperCase());
        return <Chip label={<Typography variant="uptitleBold3">{translateGenerationStatus(generation.status.toUpperCase())}</Typography>}
                     style={{border: "2px solid", borderColor: color, backgroundColor: "unset"}}/>;
    }


    function loadJobsForGenerationProcess() {
            const processIdListQuery = generation.processIdList?.map((item) => encodeURIComponent(item)).join(',');
            AxiosGetWithoutState(jobServiceApiUrl + "/jobRequest/find/byProcessIds", "GET", {processIds: processIdListQuery}, currentToken)
                .then(({loaded, error, data}) => {
                    if (error) {
                        enqueueSnackbar(error, {variant: "error"});
                        console.log("Не удалось получить задачи по загрузке для файла: \n" + error);
                    } else {
                        // debugger;
                        dispatch(addJobList(data));
                    }
                }).catch(error => {
                enqueueSnackbar("Не удалось получить задачи", {variant: "error"});
                console.log("Не удалось получить задачи: \n" + error);
            });
    }

    const showJobProcess = (id) => {
        if (jobProcessIds && jobProcessIds.length === 0)
            loadJobsForGenerationProcess();
        setJobDetailsOpen({...jobDetailsOpen, [id]: !jobDetailsOpen[id]});
    };

    const handleModalOpen = (genId) => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <React.Fragment key={generation.id}>
            <TableRow key={generation.id}>
                <StyledTableCell>{getNameBankByKeyword(generation.bank)}</StyledTableCell>
                <StyledTableCell>{generation.description}</StyledTableCell>
                <StyledTableCell>{generation.createdAt}</StyledTableCell>
                <StyledTableCell>{formatDate(generation.generatedOnDate)}</StyledTableCell>
                <StyledTableCell>{generation.operationMode}</StyledTableCell>
                <StyledTableCell>{"-"}</StyledTableCell>
                <StyledTableCell><GenerationStatusChip generation={generation}/></StyledTableCell>
                <TableCell align={"center"}><IconSettings onClick={() => handleModalOpen(generation.id)}
                                                          style={{cursor: 'pointer'}}/></TableCell>
                <TableCell align="center">
                    <IconButton size="small" onClick={() => showJobProcess(id)}>
                        {jobDetailsOpen[generation.id] ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                    <Collapse in={jobDetailsOpen[generation.id]} timeout="auto" unmountOnExit>
                        <Table aria-label="purchases" className="nested-table">
                            <TableBody
                                style={{backgroundColor: "#00c6ff17", border: "1px solid #c2c2c2"}}>
                                <TableRow>
                                    <TableCell style={{border: "1px solid #c2c2c2"}} align={"center"}
                                               sx={{color: "#2e2e2e", fontWeight: 800}}>ID</TableCell>
                                    <TableCell style={{border: "1px solid #c2c2c2"}}
                                               align={"center"} sx={{
                                        color: "#2e2e2e",
                                        fontWeight: 800
                                    }}>Параметры</TableCell>
                                    <TableCell style={{border: "1px solid #c2c2c2"}}
                                               align={"center"} sx={{
                                        color: "#2e2e2e",
                                        fontWeight: 800
                                    }}>Статус</TableCell>
                                    <TableCell style={{border: "1px solid #c2c2c2"}}
                                               align={"center"}
                                               sx={{color: "#2e2e2e", fontWeight: 800}}>Время до
                                        окончания</TableCell>
                                    <TableCell style={{border: "1px solid #c2c2c2"}}
                                               align={"center"} sx={{
                                        color: "#2e2e2e",
                                        fontWeight: 800
                                    }}>Инфо</TableCell>
                                </TableRow>
                                {jobProcessIds?.map((processId) => (
                                    <JobContainer key={processId} processId={processId}/>
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
            {modalOpen && (
                <GeneratedDataManage
                    isOpen={modalOpen}
                    onClose={handleModalClose}
                    generation={generation}
                />
            )}
        </React.Fragment>
    )
});


function GeneratedDataManage({generation, isOpen, onClose}) {
    const theme = useTheme();
    const {currentToken, setToken} = UseToken();
    const {enqueueSnackbar} = useSnackbar();
    const [fileTypes, setFileTypes] = useState([]);
    const [selectedOffer, setSelectedOffer] = useState('');
    const fileOfferInputRef = useRef(null);

    const [mediaFiles, setMediaFiles] = useState({
        IMAGE: { file: null, preview: null },
        SMALL_IMAGE: { file: null, preview: null },
        LOGO: { file: null, preview: null },
        SM_LOGO: { file: null, preview: null },
    });

    useEffect(() => {
        AxiosGetWithoutState(frameDataApiUrl + "/files/filetypes/" + generation.id, "GET", null, currentToken)
            .then(({loaded, error, data}) => {
                if (error) {
                    enqueueSnackbar(error, {variant: "error"});
                    console.log("Не удалось получить типы файлов из генерации: \n" + error);
                } else {
                    setFileTypes(data);
                }
            }).catch(error => {
            enqueueSnackbar("Не удалось получить типы файлов из генерации", {variant: "error"});
            console.log("Не удалось получить типы файлов из генерации: \n" + error);
        });
    }, [generation.id]);


    const handleFileDownload = async (selectedFileTypes) => {
        try {
            const response = await axios.get(frameDataApiUrl + "/files/" + generation.id, {
                params: {
                    fileTypes: selectedFileTypes
                },
                headers: {
                    "Content-Type": "application/json",
                    "token": currentToken,
                },
                responseType: "blob", // Для загрузки файла
            });

            // Получаем имя файла из заголовка Content-Disposition
            const contentDisposition = response.headers["content-disposition"];
            let fileName = "default_filename.zip"; // Имя по умолчанию

            if (contentDisposition) {
                const match = contentDisposition.match(/filename="?([^;"]+)"?/);
                if (match && match[1]) {
                    fileName = match[1];
                }
            }

            // Создаем ссылку для скачивания файла
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName); // Используем имя файла из API
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Ошибка при загрузке файла:", error);
        }
    };

    const handleFileUpload = (event, type) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setMediaFiles(prev => ({
                    ...prev,
                    [type]: { file: file, preview: reader.result }
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpdate = async () => {
        const url = frameDataApiUrl + '/files/media'; // Ваш API endpoint

        // Проверка, выбран ли оффер
        if (!selectedOffer) {
            enqueueSnackbar('Выберите оффер перед обновлением.', { variant: 'warning' });
            return;
        }

        // Проверка, есть ли выбранные файлы
        const filesToUpload = Object.entries(mediaFiles).filter(([_, value]) => value.file !== null);
        if (filesToUpload.length === 0) {
            enqueueSnackbar('Не выбраны файлы для загрузки.', { variant: 'warning' });
            return;
        }

        const rtmmId = selectedOffer; // Используем выбранный Offer
        const versionId = generation.id; // Установите или получите versionId

        // Асинхронная обработка файлов
        for (const [fileKey, fileData] of filesToUpload) {
            const formData = new FormData();
            formData.append('versionId', versionId);
            formData.append('file', fileData.file);
            formData.append('rtmmId', rtmmId);
            formData.append('imageType', fileKey);

            try {
                const { loaded, error, data } = await AxiosPutFile(url, formData, currentToken); // Ждем выполнения запроса

                if (error) {
                    enqueueSnackbar(error, { variant: 'error' });
                } else if (loaded) {
                    enqueueSnackbar(`Файл ${fileKey} успешно загружен`, { variant: 'success' });
                }
            } catch (error) {
                console.error(error);
                enqueueSnackbar(`Ошибка при загрузке файла ${fileKey}: ${error.message}`, { variant: 'error' });
            }
        }

        enqueueSnackbar('Все файлы успешно загружены.', { variant: 'success' });
    };

    const handleOfferChange = (id, value) => {
        setSelectedOffer(value);
    };

    // Обработчик выбора файла
    const handleOfferFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            enqueueSnackbar('Файл не выбран.', { variant: 'warning' });
            return;
        }

        const formData = new FormData();
        formData.append('offers', file);

        const url = frameDataApiUrl + '/files/offers/' + generation.id;

        try {
            const { loaded, error } = await AxiosPostFile(url, formData, currentToken);
            if (error) {
                enqueueSnackbar(`Ошибка загрузки файла: ${error}`, { variant: 'error' });
            } else if (loaded) {
                enqueueSnackbar('Файл успешно загружен.', { variant: 'success' });
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Ошибка при загрузке файла.', { variant: 'error' });
        }
    };

    // Обработчик клика на кнопку "Offers"
    const handleOfferFileClick = () => {
        if (fileOfferInputRef.current) {
            fileOfferInputRef.current.click();
        }
    };


    return (
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>{generation.description}</DialogTitle>
                <DialogContent sx={{p: '1rem !important'}}>
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Box >
                                            <Typography variant="h6">Скачать файлы</Typography>
                                        </Box>
                                        <Box sx={{mt: '0.5rem'}}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => handleFileDownload(fileTypes.join(","))} // Передаем все типы через запятую
                                                    >
                                                        Все
                                                    </Button>
                                                </Grid>

                                                {fileTypes.map((fileType, index) => (
                                                    <Grid key={index} item>
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => handleFileDownload(fileType)} // Передаем выбранный тип файла
                                                        >
                                                            {fileType}
                                                        </Button>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>

                                        <Box>
                                            <Typography variant="h6">Обновить файлы</Typography>
                                        </Box>

                                        <Box sx={{mt: '0.5rem'}}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Button variant="contained" onClick={handleOfferFileClick}>
                                                        Offers
                                                    </Button>
                                                    <input
                                                        type="file"
                                                        ref={fileOfferInputRef}
                                                        style={{ display: 'none' }}
                                                        accept=".json, .zip" // Укажите допустимые форматы файлов
                                                        onChange={handleOfferFileChange}
                                                    />
                                                </Grid>
                                                {/*<Grid item>*/}
                                                {/*    <Button variant="contained">Добавить файл</Button>*/}
                                                {/*</Grid>*/}
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>


                                {/*<Grid container spacing={2}>*/}
                                {/*    <Grid item xs={12}>*/}
                                <Box sx={{mt: '1rem'}}>
                                    <Typography variant="h6">Медиа</Typography>
                                </Box>
                                        <Grid container spacing={2} sx={{mt: '0.1rem'}}>
                                            <Grid item xs={4}>
                                                <SelectField
                                                    id="offer-select"
                                                    label="Выберите оффер"
                                                    itemList={generation.offerInfo.map(offer => ({
                                                        key: offer.rtmmId.toString(),
                                                        value: `${offer.rtmmId} - ${offer.title}`
                                                    }))}
                                                    handleChange={handleOfferChange}
                                                />
                                            </Grid>
                                            <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="h6">
                                                    Выбранный оффер: {selectedOffer ? generation.offerInfo.find(o => o.rtmmId.toString() === selectedOffer)?.title || 'Не выбрано' : 'Не выбрано'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                            <Grid item container xs={8} spacing={2} sx={{mt: '1rem'}}>
                                                {['IMAGE', 'SMALL_IMAGE', 'LOGO', 'SM_LOGO'].map((type) => (
                                                    <Grid item xs={6} key={type}>
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                height: 150,
                                                                border: '1px dashed grey',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                cursor: 'pointer',
                                                                overflow: 'hidden',
                                                            }}
                                                            onClick={() => document.getElementById(`file-input-${type}`)?.click()}
                                                        >
                                                            {mediaFiles[type].preview ? (
                                                                <img
                                                                    src={mediaFiles[type].preview}
                                                                    alt={type}
                                                                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                                                                />
                                                            ) : (
                                                                <Typography>{type.charAt(0).toUpperCase() + type.slice(1)}</Typography>
                                                            )}
                                                        </Box>
                                                        <input
                                                            id={`file-input-${type}`}
                                                            type="file"
                                                            hidden
                                                            onChange={(e) => handleFileUpload(e, type)}
                                                            accept="image/*"
                                                        />
                                                    </Grid>
                                                ))}
                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleUpdate}
                                                        startIcon={<RefreshIcon />}
                                                    >
                                                        Обновить
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                <Box sx={{ mt: 2, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                                    <Box>
                                        <Typography variant="h6">Офера</Typography>
                                    </Box>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex', // Flexbox для общей структуры
                                        flexWrap: 'wrap', // Включаем перенос элементов на новую строку
                                        gap: '0.5rem', // Промежутки между элементами
                                        justifyContent: 'flex-start', // Элементы выравниваются в начале строки
                                        alignItems: 'center', // Центрирование элементов по вертикали
                                    }}
                                >
                                    {generation.offerInfo
                                        .slice() // Создаем копию массива, чтобы не изменять оригинал
                                        .sort((a, b) => a.rtmmId - b.rtmmId)
                                        .map((offerInfo) => (
                                        <Box
                                            key={offerInfo.rtmmId}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontWeight: 'bold',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    backgroundColor: '#5d87ff', // Синий цвет фона
                                                    color: '#fff', // Белый цвет текста
                                                    borderRadius: '1rem 0rem 0rem 1rem', // Скругление углов
                                                    padding: '0.2rem 0.5rem', // Внутренние отступы
                                                    border: '0.2rem #5d87ff solid',
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{ flex: 1, textAlign: 'center', textOverflow: 'ellipsis' }}
                                                >
                                                    {offerInfo.rtmmId}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    borderRadius: '0rem 1rem 1rem 0rem', // Скругление углов
                                                    padding: '0.2rem 0.5rem', // Внутренние отступы
                                                    border: '0.2rem #5d87ff solid',
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{ flex: 1, textAlign: 'center', textOverflow: 'ellipsis', textWrapMode: 'nowrap' }}
                                                >
                                                    {offerInfo.title}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </>
                </DialogContent>
            </Dialog>
        );
}

// function AdmitadBillingLogRow({id,index, log}) {
//     return(
//         <DashboardCard>
//             <Grid container spacing={2}>
//                 <Grid item xs={9}>
//                     <Grid container spacing={2}>
//                         <Grid item xs={6}>
//                                 <Box >
//                                     <Typography variant="h6">Скачать файлы</Typography>
//                                 </Box>
//                                 <Box sx={{mt: '0.5rem'}}>
//                                 <Grid container spacing={2}>
//                                     <Grid item>
//                                         <Button variant="contained">Все</Button>
//                                     </Grid>
//                                     <Grid item>
//                                         <Button variant="contained">Offers</Button>
//                                     </Grid>
//                                     <Grid item>
//                                         <Button variant="contained">Clients</Button>
//                                     </Grid>
//                                     <Grid item>
//                                         <Button variant="contained">Operations</Button>
//                                     </Grid>
//                                 </Grid>
//                                 </Box>
//                         </Grid>
//                         <Grid item xs={6}>
//
//                                 <Box>
//                                     <Typography variant="h6">Обновить файлы</Typography>
//                                 </Box>
//
//                             <Box sx={{mt: '0.5rem'}}>
//                                 <Grid container spacing={2}>
//                                     <Grid item>
//                                         <Button variant="contained">Offers</Button>
//                                     </Grid>
//                                     <Grid item>
//                                         <Button variant="contained">Media</Button>
//                                     </Grid>
//                                     <Grid item>
//                                         <Button variant="contained">Добавить файл</Button>
//                                     </Grid>
//                                 </Grid>
//                             </Box>
//                         </Grid>
//                     </Grid>
//                     <Box sx={{ mt: 2, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
//                         <Box>
//                             <Typography variant="h6">Генерация файлов на май</Typography>
//                         </Box>
//                     </Box>
//
//                     <Grid container spacing={1} alignItems="center" textAlign="center">
//                         <Grid item xs={2.5} >
//                             <Box sx={{
//                                 backgroundColor: '#5d87ff', // Синий цвет фона
//                                 borderRadius: '1rem', // Скругление углов
//                                 padding: '0.2rem 0.5rem', // Внутренние отступы
//                                 display: 'flex', // Flexbox для выравнивания
//                                 alignItems: 'center', // Центрирование по вертикали
//                                 color: '#fff', // Белый цвет текста
//                                 fontWeight: 'bold', // Жирный текст
//                                 justifyContent: 'center' // Центрирование по горизонтали
//                             }}>
//
//                                 <Typography variant="body1" sx={{ flex: 1, textAlign: 'center', textOverflow: 'ellipsis' }}>36501</Typography>
//                                 <Box sx={{ mx: 1 }}> | </Box> {/* Разделитель */}
//                                 <Typography variant="body1" sx={{ flex: 1, textAlign: 'center', textOverflow: 'ellipsis' }}>Леонардо</Typography>
//                             </Box>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//             </Grid>
//         </DashboardCard>
//     )
// }


function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export default FrameDataGenerationView;

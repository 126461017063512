import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {updateJobParam} from "../../views/operational_task/reducers/jobRequestSlice";


export default function MultiSelectField ({ paramId }) {
    const dispatch = useDispatch();

    // Извлекаем данные из Redux Store
    const param = useSelector((state) =>
        state.jobRequest.jobData.jobParams.find((p) => p.id === paramId)
    );

    const selectedValues = param?.value || [];
    const itemList = param?.jobParamValues || [];

    // Обработчик изменения значения
    const handleChange = (event) => {
        const newValue = event.target.value;
        dispatch(updateJobParam({ paramId, newValue })); // Вызываем экшен
    };

    const sortedValues = [...itemList].sort((a, b) => {
        if (a.value < b.value) {
            return -1;
        } else if (a.value > b.value) {
            return 1;
        } else {
            return 0;
        }
    });

    const renderSelectedValues = () => {
        if (selectedValues.length === 0) {
            return "Select...";
        } else {
            return selectedValues
                .map((value) => sortedValues.find((item) => item.key === value)?.value)
                .join(", ");
        }
    };

    const menuItemStyle = (selected) => ({
        color: selected ? '#5D87FF' : 'inherit',
        fontWeight: selected ? 'bold' : 'normal'
    });


    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Значение</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedValues}
                multiple
                label="Age"
                onChange={handleChange}
                renderValue={renderSelectedValues}
            >

                {sortedValues.map((item, index) => {
                    return (
                        <MenuItem key={item.key} value={item.key} sx={menuItemStyle(selectedValues.includes(item.key))}>{item.value}</MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    )
}

import './App.css';
import {useRoutes} from 'react-router-dom';
import Router from './schema/Router';


import { baseTheme } from "./schema/theme/BaseTheme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {SnackbarProvider} from "notistack";
import UseToken from "./auth/UseToken";
import Login from "./views/authentication/Login";
import {TokenContext} from "./schema/Contexts";
import {store} from "./views/operational_task/store/store";
import {Provider, useDispatch} from 'react-redux';
import {useEffect} from "react";
import {billingServiceApiUrl, fileServiceApiUrl, frameDataApiUrl, processServiceApiUrl} from "./schema/Environment";
import {setupWebSocket} from "./views/operational_task/store/webSocket/websocketManager";



function App() {
    const routing = useRoutes(Router);
    const {currentToken, setToken} = UseToken();

    if (currentToken) {
        const decodedToken = JSON.parse(atob(currentToken.split('.')[1]));
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp < currentTime) {
            setToken(null)
        }
    }

    if(currentToken) {
        return (
            <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                <TokenContext.Provider value={[currentToken, setToken]}>
                    <ThemeProvider theme={baseTheme}>
                        <CssBaseline/>
                        <Provider store={store}>
                            <InitWebSocket/>
                            {routing}
                        </Provider>
                    </ThemeProvider>
                </TokenContext.Provider>
            </SnackbarProvider>
        );
    }
    else{
        return (
            <TokenContext.Provider value={[currentToken, setToken]}>
                <Login/>
            </TokenContext.Provider>
        )
    }
}

function InitWebSocket() {
    const dispatch = useDispatch();
    const {currentToken, setToken} = UseToken();

    useEffect(() => {
        setupWebSocket('fileService', fileServiceApiUrl + '/socket', dispatch, currentToken);
        setupWebSocket('billingService', billingServiceApiUrl + '/socket', dispatch, currentToken);
        setupWebSocket('processService', processServiceApiUrl + '/socket', dispatch, currentToken);
        setupWebSocket('frameDataGenerationService', frameDataApiUrl + '/socket', dispatch, currentToken);
    }, [dispatch]);

    return null;
}

export default App;

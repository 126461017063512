import React from "react";
import { TextField, FormControl } from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {updateJobParam} from "../../views/operational_task/reducers/jobRequestSlice";

export default function StringField({ paramId, label }) {
    const dispatch = useDispatch();

    // Извлекаем значение из Redux Store
    const value = useSelector((state) =>
        state.jobRequest.jobData.jobParams.find((p) => p.id === paramId)?.value || ""
    );

    // Обработчик изменений
    const handleChange = (event) => {
        const newValue = event.target.value;
        dispatch(updateJobParam({ paramId, newValue })); // Вызываем экшен для обновления Redux Store
    };

    return (
        <FormControl fullWidth>
            <TextField
                id={`string-field-${paramId}`}
                label={label || "Введите значение"}
                variant="outlined"
                value={value}
                onChange={handleChange}
            />
        </FormControl>
    );
}

import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import {setConnectionStatus} from "../../reducers/websocketSlice";


const websockets = {};

export const setupWebSocket = (urlKey, url, dispatch, currentToken) => {
    if (websockets[urlKey]) {
        console.log(`WebSocket for ${urlKey} already set up.`);
        return websockets[urlKey];
    }

    let sock = new SockJS(url);
    let stompClient = Stomp.over(sock);

    stompClient.connect({ "token": `${currentToken}` }, frame => {
        console.log(`Connected to ${urlKey}: ${frame}`);
        dispatch(setConnectionStatus({ urlKey, isConnected: true }));

        const websocketInterface = {
            subscribe: (topic, onMessage) => {
                let subscription = stompClient.subscribe(topic, message => {
                    onMessage(JSON.parse(message.body));
                });
                websockets[urlKey].subscriptions[topic] = subscription;
            },
            disconnect: () => {
                Object.values(websockets[urlKey].subscriptions).forEach(sub => sub.unsubscribe());
                stompClient.disconnect();
                console.log(`Disconnected from ${urlKey}`);
                delete websockets[urlKey];
            }
        };

        websockets[urlKey] = {
            stompClient,
            subscriptions: {},
            ...websocketInterface
        };
    }, error => {
        console.error(`Connection error at ${urlKey}: ${error}`);
        dispatch(setConnectionStatus({ urlKey, isConnected: false }));
    });

    sock.onclose = event => {
        console.log(`Connection closed at ${urlKey}: ${event}`);
        dispatch(setConnectionStatus({ urlKey, isConnected: false }));
    };

    return websockets[urlKey];
};

export const getWebSocket = (urlKey) => {
    return websockets[urlKey];
};
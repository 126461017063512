import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {updateJobParam} from "../../views/operational_task/reducers/jobRequestSlice";

export function SelectField (props) {

    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    useEffect(() => {
        if (props.handleChange) {
            props.handleChange(props.id, selectedValue);
        }
    }, [selectedValue]);


    const sortedValues = props.itemList;
        if(props.paramsSorted === undefined || props.paramsSorted !== true)
            sortedValues.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));

    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{props.label ? props.label : "Значение"}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedValue}
                label={props.label ? props.label : "Значение"}
                onChange={handleChange}
            >
                {sortedValues.map((item) => (
                    <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}


export function SelectFieldBase({ paramId, label = "Значение", paramsSorted }) {

    const dispatch = useDispatch();

    // Извлекаем данные из Redux Store
    const param = useSelector((state) =>
        state.jobRequest.jobData.jobParams.find((p) => p.id === paramId)
    );

    const selectedValue = param?.value || ""; // Текущее значение
    const itemList = param?.jobParamValues || []; // Доступные значения

    // Обработчик изменения значения
    const handleChange = (event) => {
        const newValue = event.target.value;
        dispatch(updateJobParam({ paramId, newValue })); // Вызываем экшен для обновления Redux
    };

    // Сортировка значений
    const sortedValues = [...itemList];
    if (paramsSorted === undefined || paramsSorted !== true) {
        sortedValues.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
    }

    return (
        <FormControl fullWidth>
            <InputLabel id={`select-label-${paramId}`}>{label}</InputLabel>
            <Select
                labelId={`select-label-${paramId}`}
                id={`select-${paramId}`}
                value={selectedValue}
                label={label}
                onChange={handleChange}
            >
                {sortedValues.map((item) => (
                    <MenuItem key={item.key} value={item.key}>
                        {item.value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}


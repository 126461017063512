import React, {useEffect, useState} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {updateJobParam} from "../../views/operational_task/reducers/jobRequestSlice";

export function CustomDatePicker (props) {
    const [value, setValue] = useState(null);

    const handleChange = (value) => {
        setValue(value);
    };

    useEffect(() => {
        if(props.handleChange){
            const date = dayjs(value);
            const formattedDate = date.format('YYYY-MM-DD');
            props.handleChange(props.id, formattedDate);
        }
    }, [value]);

    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                value={value}
                onChange={(newValue) => handleChange(newValue)}
                format="YYYY-MM-DD"
                sx={{ width: '100%' }}
             views={['year', 'month', 'day']}/>
        </LocalizationProvider>
    )
}


export function DatePickerBase({ paramId }) {
    const dispatch = useDispatch();

    // Извлекаем значение из Redux Store
    const value = useSelector((state) =>
        state.jobRequest.jobData.jobParams.find((p) => p.id === paramId)?.value || null
    );

    // Обработчик изменений
    const handleChange = (newValue) => {
        const date = dayjs(newValue);
        const formattedDate = date.isValid() ? date.format("YYYY-MM-DD") : null;
        dispatch(updateJobParam({ paramId, newValue: formattedDate })); // Обновляем Redux Store
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                value={value ? dayjs(value) : null} // Преобразуем строку в DayJS объект
                onChange={(newValue) => handleChange(newValue)}
                format="YYYY-MM-DD"
                sx={{ width: "100%" }}
                views={["year", "month", "day"]}
            />
        </LocalizationProvider>
    );
}

import React, {useEffect, useMemo, useState} from 'react';
import {
    Button, Chip,
    Collapse, Dialog, DialogContent, DialogTitle, Grid,
    IconButton,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from '@mui/material';
import PageContainer from '../../components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import TypeSwitchBar from "../../layouts/full/TypeSwitchBar/TypeSwitchBar";
import {IconLayoutDashboard} from "@tabler/icons";
import {useSnackbar} from "notistack";
import {AxiosGet, AxiosGetWithoutState} from "../../axios/AxiosGet";
import {billingServiceApiUrl, fileServiceApiUrl, jobServiceApiUrl} from "../../schema/Environment";

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AnalyticsIcon from '@mui/icons-material/Analytics';

import JobLauncherBox from "./JobLauncherBox";
import {useDispatch, useSelector} from "react-redux";
import {groupBy} from "lodash";
import {Banks, getColorBankByKeyword, getNameBankByKeyword, setSelectedTypeId} from "./reducers/bankFilterSlice";
import {useTheme} from "@mui/material/styles";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {
    addJobList,
    selectJobsByProcessIds
} from "./reducers/jobsSlice";
import UseToken from "../../auth/UseToken";

import {JobContainer} from "./JobsTable";
import {LoaderContent} from "../modal/RowsDataModal";
import {CustomContentForLoadAndErrorWithContent} from "../../components/CustomContentForLoadAndError";
import {
    addBillingInfo,
    addBillingInfoList,
    cleanBillingList,
    selectBillingById,
    selectSortedBillingsByDate, translateBillingStatus, translateBillingType,
} from "./reducers/billingSlice";
import {
    closeBillingProcessStatisticModal,
    openBillingProcessStatisticModal
} from "./reducers/modalBillingProcessStatisticSlice";
import {getWebSocket} from "./store/webSocket/websocketManager";
import {AxiosPostWithoutState} from "../../axios/AxiosPost";
import {SelectField} from "../../components/inputs/SelectField";
import {CustomDatePicker} from "../../components/inputs/CustomDatePicker";
import {IconClipboardTypography, IconFile, IconBuildingBank} from "@tabler/icons-react";
import {setSelectedBillingStatus} from "./reducers/billingStatusFilterSlice";
import {setSelectedBillingType} from "./reducers/billingTypeFilterSlice";



function BillingManagerView () {
    const { enqueueSnackbar } = useSnackbar();
    const {currentToken, setToken} = UseToken();
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        AxiosGetWithoutState(billingServiceApiUrl + "/billing/findAll", "GET", null, currentToken).then(({loaded, error, data}) => {

            setLoaded(true);
            if(error){
                enqueueSnackbar("Не удалось получить файлы: \n" + error, {variant: "error"});
                console.log("Не удалось получить файлы: \n" + error);
            }
            else {
                dispatch(cleanBillingList())
                dispatch(addBillingInfoList(data));
            }
        }).catch(error => {
            enqueueSnackbar("Не удалось получить задачи", {variant: "error"});
            console.log("Не удалось получить задачи: \n" + error);
        });
    }, []);

    if(loaded === false){
        return (
            <PageContainer title="Sample Page" description="this is Sample page">
                <DashboardCard>
                    <Skeleton />
                </DashboardCard>
            </PageContainer>
        )
    }
    else {
        // debugger;
        return (
            <PageContainer title="Sample Page" description="this is Sample page">
                <BankFilter/>
                <BillingTypeFilter/>
                <BillingStatusFilter/>
                <BillingJobLauncherView/>
                <BillingListFromFileRow/>
                <BillingListFromCampaignActiveForDate/>
                <BillingListView/>
                <UploadPreviousPeriod/>
            </PageContainer>
        );
    }
}

function BillingsByFilters() {
    const selectedBankFilter = useSelector((state) => state.bankFilter.selectedTypeId);
    const selectedBillingTypeFilter = useSelector((state) => state.billingTypeFilter.selectedBillingType);
    const selectedBillingStatusFilter = useSelector((state) => state.billingStatusFilter.selectedBillingStatus);
    const billingList = useSelector(selectSortedBillingsByDate);


    let filteredBillingList = billingList;
    if (selectedBankFilter) {
        filteredBillingList = billingList.filter(j => j.bank === selectedBankFilter);
    }

    if (selectedBillingTypeFilter) {
        if (filteredBillingList.filter(j => j.type === selectedBillingTypeFilter).length > 0)
            filteredBillingList = filteredBillingList.filter(j => j.type === selectedBillingTypeFilter);
    }

    if (selectedBillingStatusFilter) {
        if (filteredBillingList.filter(j => j.status === selectedBillingStatusFilter).length > 0)
            filteredBillingList = filteredBillingList.filter(j => j.status === selectedBillingStatusFilter);
    }

    return filteredBillingList;

}



function UploadPreviousPeriod() {
    const {enqueueSnackbar} = useSnackbar();
    const {currentToken, setToken} = UseToken();
    const [endDate, setEndDate] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const date = new Date();
        date.setDate(date.getDate() - 7);
        setEndDate(date);
    }, []);

    function loadBillings(countDays){
        setLoading(true);
        setError(null);
        const startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - countDays);


        AxiosGetWithoutState(billingServiceApiUrl + "/billing/findByDateRange", "GET", {
            startDate: startDate.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0]
        }, currentToken).then(({loaded, error, data}) => {
            if(error){
                enqueueSnackbar("Не удалось получить информацию о предыдущих биллингах: \n" + error, {variant: "error"});
                console.log("Не удалось получить информацию о предыдущих биллингах: \n" + error);
            }
            else {
                dispatch(addBillingInfoList(data));
                setEndDate(startDate);
            }
        }).catch(error => {
            enqueueSnackbar("Не удалось получить информацию о предыдущих биллингах", {variant: "error"});
            console.log("Не удалось получить информацию о предыдущих биллингах: \n" + error);
        });
        setLoading(false);
    };

    return (
        <>
            <DashboardCard>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item xs={6} alignItems="center">
                        <Typography variant="h6">Добавить данные</Typography>
                    </Grid>
                    <Grid item xs={6} alignItems="center" style={{ textAlign: 'right' }}>
                        <Grid container spacing={2}  justifyContent="flex-end">
                            <Grid item alignItems="center">
                                <Button variant="contained" color="primary" onClick={() => loadBillings(7)}>
                                    За неделю
                                </Button>
                            </Grid>
                            <Grid item alignItems="center">
                                <Button variant="contained" color="primary" onClick={() => loadBillings(30)}>
                                    За месяц
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DashboardCard>
        </>
    );
}

function BillingListFromCampaignActiveForDate() {

    const dispatch = useDispatch();
    const theme = useTheme();
    const {currentToken, setToken} = UseToken();
    const { enqueueSnackbar } = useSnackbar();
    const [billingList, setBillingList] = useState([]);
    const [paramsForQueryCampaign, setParamsForQueryCampaign] = useState({
        date: null,
        bank: null
    });


    const banksParams = Banks.map(bank => ({
        key: bank.id,
        value: bank.name
    }));


    const handleGetCampaigns = () => {

        if(paramsForQueryCampaign.bank === null || paramsForQueryCampaign.date === null){
            enqueueSnackbar("Необходимо заполнить все поля", {variant: "error"});
            return;
        }

        AxiosGetWithoutState(billingServiceApiUrl + "/billing/get/byActiveCampaignsForDate", "GET",{bank: paramsForQueryCampaign.bank, date: paramsForQueryCampaign.date},  currentToken).then(({loaded, error, data}) => {
            if(error){
                enqueueSnackbar(error, {variant: "error"});
            }
            else {
                setBillingList(data)
            }
        }).catch(error => {
            console.log(error);
            enqueueSnackbar("Непредвиденная ошибка при удалении задачи", {variant: "error"});
        });
    };

    const handleNewBillings = () => {
        AxiosPostWithoutState(billingServiceApiUrl + "/billing/create/new/list",null, billingList,  currentToken).then(({loaded, error, data}) => {
            if(error){
                enqueueSnackbar(error, {variant: "error"});
            }
            else {
                enqueueSnackbar("Задачи по биллингам созданы", {variant: "success"});
                setBillingList([]);
                dispatch(addBillingInfoList(data));
            }
        }).catch(error => {
            console.log(error);
            enqueueSnackbar("Непредвиденная ошибка при удалении задачи", {variant: "error"});
        });
    };

    function handleParamChange(paramId, newValue) {
        // Обновляем состояние paramsForQueryCampaign, изменяя только нужное поле
        setParamsForQueryCampaign(prevState => ({
            ...prevState,
            [paramId]: newValue  // Используем вычисляемое свойство имени для динамического обновления
        }));
    }


    return (
        <>
            <DashboardCard>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item xs={6} alignItems="center">
                        <Typography variant="h6">Биллинг по кампаниям активным на дату</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <SelectField  id={"bank"} label={"Банк"} itemList={banksParams} handleChange={handleParamChange} />
                        <CustomDatePicker id={"date"} handleChange={handleParamChange}/>
                        <Button sx={{marginLeft: "1rem", width: "15rem", lineHeight: "120%"}} variant="contained" color="primary" onClick={handleGetCampaigns}>
                            Получить кампании
                        </Button>
                    </Grid>
                </Grid>
            </DashboardCard>

            {billingList.length > 0 ?
                <>
                    <TableContainer component={Paper} style={{paddingBottom: "1rem", marginBottom: "1rem"}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={"center"} sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.primary.light,
                                        fontWeight: 800
                                    }}>Банк</TableCell>
                                    <TableCell align={"center"} sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.primary.light,
                                        fontWeight: 800
                                    }}>Тип биллинга</TableCell>
                                    <TableCell align={"center"} sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.primary.light,
                                        fontWeight: 800,
                                        maxWidth: "20rem"
                                    }}>Кампании</TableCell>
                                    <TableCell align={"center"} sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.primary.light,
                                        fontWeight: 800
                                    }}>Дата начала</TableCell>
                                    <TableCell align={"center"} sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.primary.light,
                                        fontWeight: 800
                                    }}>Дата окончания</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {billingList.map((billing, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center"><Typography variant="uptitleBold3">{getNameBankByKeyword(billing.bank)}</Typography></TableCell>
                                        <TableCell align="center"><Typography variant="uptitleBold3">{billing.type}</Typography></TableCell>
                                        <TableCell align="center" sx={{maxWidth: '20rem', overflow: 'auto'}}><Typography variant="uptitleBold3">{billing.rtmmIds}</Typography></TableCell>
                                        <TableCell align="center"><Typography variant="uptitleBold3">{billing.startDate}</Typography></TableCell>
                                        <TableCell align="center"><Typography variant="uptitleBold3">{billing.endDate}</Typography></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>

                    <DashboardCard>
                        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                            <Grid item xs={6} alignItems="center">
                                <Typography variant="h6">Отправить на выполнение</Typography>
                            </Grid>
                            <Grid item xs={6} alignItems="center" style={{ textAlign: 'right' }}>
                                <Button variant="contained" color="primary" onClick={handleNewBillings}>
                                    Отправить
                                </Button>
                            </Grid>
                        </Grid>
                    </DashboardCard>
                </>
                : null}
        </>
    )

}

function BillingListFromFileRow() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const {currentToken, setToken} = UseToken();
    const { enqueueSnackbar } = useSnackbar();
    const [billingList, setBillingList] = useState([]);

    function parseData(data) {

        const typeMapping = {
            'WHITE': 'BILLING_WHITE',
            'GREY': 'BILLING_GREY',
            'GREYALL': 'BILLING_GREY_WITHOUT_CLIENTS'
        };

        const banksParams = Banks.map(bank => ({
            key: bank.old_id.trim(), // Используем полное название банка как значение
            value: bank.id.toLowerCase() // Используем id, приведенный к нижнему регистру, как ключ
        }));

        let invalidRows = [];
        data.split('\n').forEach(row => {
            const [bank, rtmmIds, startDate, endDate, type] = row.split(';');
            if(banksParams.filter(b=> b.key === bank.trim().toLowerCase()).length === 0) {
                // debugger;
                enqueueSnackbar("Неизвестное значение банка: " + bank, {variant: "error"});
                invalidRows.push(row);
            }
        });

        if(invalidRows.length > 0) {
            return [];
        }
        else
            return data.split('\n').map(row => {
                const [bank, rtmmIds, startDate, endDate, type] = row.split(';');
                return {
                    bank: banksParams.filter(b=> b.key === bank.trim().toLowerCase())[0].value|| 'Неизвестное значение',
                    rtmmIds: rtmmIds,
                    startDate: new Date(startDate).toISOString().split('T')[0],  // Преобразуем в формат yyyy-MM-dd
                    endDate: new Date(endDate).toISOString().split('T')[0],
                    type: typeMapping[type.trim().toUpperCase()] || 'Неизвестное значение',  // Предполагаем, что статусы на сервере ENUM в верхнем регистре
                    created: new Date().toISOString()  // Текущая дата и время создания
                };
            });
    }

    const handleNewBillings = () => {
        AxiosPostWithoutState(billingServiceApiUrl + "/billing/create/new/list",null, billingList,  currentToken).then(({loaded, error, data}) => {
            if(error){
                enqueueSnackbar(error, {variant: "error"});
            }
            else {
                enqueueSnackbar("Задачи по биллингам созданы", {variant: "success"});
                setBillingList([]);
                dispatch(addBillingInfoList(data));
            }
        }).catch(error => {
            console.log(error);
            enqueueSnackbar("Непредвиденная ошибка при удалении задачи", {variant: "error"});
        });
    };

    const handleFileChange = event => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            const parsedData = parseData(text);
            setBillingList(parsedData);
        };
        reader.readAsText(file);
    };

    return (
        <>
            <DashboardCard>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item xs={6} alignItems="center">
                        <Typography variant="h6">Биллинг по параметрам из файла</Typography>
                    </Grid>
                    <Grid item xs={6} alignItems="center" style={{ textAlign: 'right' }}>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            accept=".txt, text/plain"
                        />
                    </Grid>
                </Grid>
            </DashboardCard>

            {billingList.length > 0 ?
                <>
            <TableContainer component={Paper} style={{paddingBottom: "1rem", marginBottom: "1rem"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Банк</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Тип биллинга</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800,
                                maxWidth: "20rem"
                            }}>Кампании</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Дата начала</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Дата окончания</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {billingList.map((billing, index) => (
                            <TableRow key={index}>
                                <TableCell align="center"><Typography variant="uptitleBold3">{getNameBankByKeyword(billing.bank)}</Typography></TableCell>
                                <TableCell align="center"><Typography variant="uptitleBold3">{billing.type}</Typography></TableCell>
                                <TableCell align="center" sx={{maxWidth: '20rem', overflow: 'auto'}}><Typography variant="uptitleBold3">{billing.rtmmIds}</Typography></TableCell>
                                <TableCell align="center"><Typography variant="uptitleBold3">{billing.startDate}</Typography></TableCell>
                                <TableCell align="center"><Typography variant="uptitleBold3">{billing.endDate}</Typography></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>

                    <DashboardCard>
                        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                            <Grid item xs={6} alignItems="center">
                                <Typography variant="h6">Отправить на выполнение</Typography>
                            </Grid>
                            <Grid item xs={6} alignItems="center" style={{ textAlign: 'right' }}>
                                <Button variant="contained" color="primary" onClick={handleNewBillings}>
                                    Отправить
                                </Button>
                            </Grid>
                        </Grid>
                    </DashboardCard>
                </>
             : null}
        </>
    )

}

function BillingJobLauncherView (){
    const { enqueueSnackbar } = useSnackbar();
    const {loaded, error, data} = AxiosGet(jobServiceApiUrl + "/job/find/all", "GET", null, enqueueSnackbar);
    const [selectedJob, setSelectedJob] = useState(null);
    const selectedBillingTypeFilter = useSelector((state) => state.billingTypeFilter.selectedBillingType);

    // debugger;
    useEffect(() => {
        if (data && selectedBillingTypeFilter) {
            const foundJob = data.find(job => job.launchType === selectedBillingTypeFilter);
            if (foundJob) {
                // Создаем новый JobParam
                const newJobParam = {
                    jobId: foundJob.id,
                    name: "LAUNCH_TYPE",
                    description: "Укажите тип запуска", // Пример описания
                    jobParamType: "STRING_FROM_VALUE_LIST",
                    required: true,
                    jobParamValues: [
                        { value: "Белый биллинг", key: "BILLING_WHITE" },
                        { value: "Серый биллинг", key: "BILLING_GREY" },
                        { value: "Серый биллинг без клиентов", key: "BILLING_GREY_WITHOUT_CLIENTS" }
                    ]
                };

                // Добавляем новый JobParam в массив jobParams найденной работы
                const updatedJobParams = foundJob.jobParams.concat(newJobParam);

                // Создаем копию найденной работы с обновленным массивом jobParams
                const updatedJob = {
                    ...foundJob,
                    jobParams: updatedJobParams
                };

                setSelectedJob(updatedJob);
            } else {
                setSelectedJob(null); // или другое действие, если работа не найдена
            }
        }
    }, [data, selectedBillingTypeFilter]);

    if(data) {
        // debugger;

        return (
            <>
                {selectedJob ?
                        <>
                            <JobLauncherBox job={selectedJob} url={billingServiceApiUrl + "/billing/create/new/listByParams"}/>
                        </>
                        : <></>
                }
            </>
        );
    }

    return (
        <></>
    )
}

function BillingListView (){
    const theme = useTheme();
    const dispatch = useDispatch();
    const billingList = BillingsByFilters();
    const { isOpen, url } = useSelector((state) => state.modalBillingProcessStatistic);

    // Функция для закрытия модального окна
    const handleStatModalClose = () => {
        dispatch(closeBillingProcessStatisticModal());
    };

    return (
        <>
            <TableContainer component={Paper} style={{paddingBottom: "1rem", marginBottom: "1rem"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Банк</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Дата создания</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Тип биллинга</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800,
                                maxWidth: "20rem"
                            }}>Кампании</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Статус</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Дата начала</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Дата окончания</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Статистика</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>-</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {billingList.map((billing, index) => (
                            <BillingRow id={billing.id} index={index}/>
                            ))}
                    </TableBody>

                </Table>
            </TableContainer>
            {isOpen && (
                <BillingStatisticDataModal
                    url={url}
                    isOpen={isOpen}
                    onClose={handleStatModalClose}
                />
            )}
        </>
    );
}

const BillingRow = React.memo(({id, index}) => {
    const dispatch = useDispatch();
    const billing = useSelector(state => selectBillingById(state, id));
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState({});
    const {currentToken, setToken} = UseToken();
    const processIds = useMemo(() => billing.billingProcessList.map(item => item.processId), [billing.billingProcessList]);
    const jobProcessIds = useSelector(state => selectJobsByProcessIds(state, processIds));


    const urlForStatData = billingServiceApiUrl +  "/statistic/find/byBillingId?billingId=";

    // Функция для открытия модального окна
    const handleStatModalOpen = (billingId) => {
        dispatch(openBillingProcessStatisticModal({url: urlForStatData + billingId }));
    };

    const showBillingProcesses = (id) => {
        if(jobProcessIds && jobProcessIds.length === 0)
            loadJobsForBilling();
        setOpen({ ...open, [id]: !open[id] });
    };

    // useEffect(() => {
    //     if (jobProcessIds && jobProcessIds.length === 0)
    //         loadJobsForBilling();
    // }, [id]);

    function loadJobsForBilling(){
        if(billing) {
            const processIdListQuery = billing.billingProcessList.map(item => encodeURIComponent(item.processId)).join(',');
            AxiosGetWithoutState(jobServiceApiUrl + "/jobRequest/find/byProcessIds", "GET", {processIds: processIdListQuery}, currentToken).then(({
                                                                                                                                                      loaded,
                                                                                                                                                      error,
                                                                                                                                                      data
                                                                                                                                                  }) => {
                if (error) {
                    enqueueSnackbar(error, {variant: "error"});
                    console.log("Не удалось получить задачи по выполнению биллинга: \n" + error);
                } else {
                    dispatch(addJobList(data));
                }
            }).catch(error => {
                enqueueSnackbar("Не удалось получить задачи", {variant: "error"});
                console.log("Не удалось получить задачи: \n" + error);
            });
        }
    }

    useEffect(() => {
        if(billing && (billing.status !== "Завершен" && billing.status !== "Ошибка при проведении биллинга")) {

            const topic = '/billingInfo/' + billing.id;

            const onMessage = (updatedBillingInfo) => {
                console.log("updatedBillingInfo: ", updatedBillingInfo);
                dispatch(addBillingInfo(updatedBillingInfo));
            };

            const webSocket = getWebSocket('billingService');

            if (webSocket) {
                webSocket.subscribe(topic, onMessage);
            }
        }
    }, [id]);

    function BillingStatusChip({billing}) {
        const color = getColorBillingStatusByKeyword(billing.status);
        return <Chip label={<Typography variant="uptitleBold3">{translateBillingStatus(billing.status)}</Typography>}
                     style={{border: "2px solid", borderColor: color, backgroundColor: "unset"}}/>;
    }


    if(billing) {
        // debugger;
        return (
            <React.Fragment key={id}>
                <TableRow key={index}>
                    <TableCell align="center"><Typography variant="uptitleBold3">{getNameBankByKeyword(billing.bank)}</Typography></TableCell>
                    <TableCell align="center"><Typography variant="uptitleBold3">{formatDate(billing.created)}</Typography></TableCell>
                    <TableCell align="center"><Typography variant="uptitleBold3" sx={{borderBottom: "2px solid " + getColorBillingTypeByKeyword(billing.type)}}>{translateBillingType(billing.type)}</Typography></TableCell>
                    <TableCell align="center" sx={{maxWidth: '20rem', overflow: 'auto'}}><Typography variant="uptitleBold3">{billing.rtmmIds}</Typography></TableCell>
                    <TableCell align="center"><BillingStatusChip billing={billing}/></TableCell>
                    <TableCell align="center"><Typography variant="uptitleBold3">{formatDate(billing.startDate)}</Typography></TableCell>
                    <TableCell align="center"><Typography variant="uptitleBold3">{formatDate(billing.endDate)}</Typography></TableCell>
                    <TableCell align="center"><AnalyticsIcon onClick={() => handleStatModalOpen(billing.id)} style={{ cursor: 'pointer' }} /></TableCell>
                    <TableCell align="center">
                        <IconButton size="small" onClick={() => showBillingProcesses(id)}>
                            {open[id] ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                        <Collapse in={open[id]} timeout="auto" unmountOnExit>
                            <Table aria-label="purchases" className="nested-table">
                                <TableBody
                                    style={{backgroundColor: "#00c6ff17", border: "1px solid #c2c2c2"}}>
                                    <TableRow>
                                        <TableCell style={{border: "1px solid #c2c2c2"}} align={"center"}
                                                   sx={{color: "#2e2e2e", fontWeight: 800}}>ID</TableCell>
                                        <TableCell style={{border: "1px solid #c2c2c2"}}
                                                   align={"center"} sx={{
                                            color: "#2e2e2e",
                                            fontWeight: 800
                                        }}>Параметры</TableCell>
                                        <TableCell style={{border: "1px solid #c2c2c2"}}
                                                   align={"center"} sx={{
                                            color: "#2e2e2e",
                                            fontWeight: 800
                                        }}>Статус</TableCell>
                                        <TableCell style={{border: "1px solid #c2c2c2"}}
                                                   align={"center"}
                                                   sx={{color: "#2e2e2e", fontWeight: 800}}>Время до
                                            окончания</TableCell>
                                        <TableCell style={{border: "1px solid #c2c2c2"}}
                                                   align={"center"} sx={{
                                            color: "#2e2e2e",
                                            fontWeight: 800
                                        }}>Инфо</TableCell>
                                    </TableRow>
                                    {jobProcessIds?.map((processId) => (
                                        <JobContainer key={processId} processId={processId}/>
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }
})


export const BillingStatuses = [
    { name: "Создан", id: "CREATED", color: 'rgb(6,194,180)' },
    { name: "В очереди", id: "IN_QUEUE", color: 'rgb(230,157,0)' },
    { name: "Отправлен на выполнение", id: "SEND_TO_EXECUTE", color: 'rgb(230,157,0)' },
    { name: "Выполняется", id: "RUNNING", color: 'rgb(0, 0, 255)' },
    { name: "Завершен", id: "COMPLETED", color: 'rgb(51,135,0)' },
    { name: "Ошибка", id: "ERROR", color: 'rgb(149,2,2)' },
    { name: "Недостаточно памяти", id: "NOT_ENOUGH_MEMORY", color: 'rgb(149,2,2)' },
    { name: "Нехватило памяти", id: "NEED_MORE_MEMORY", color: 'rgb(149,2,2)' },
    { name: "Выполняется остановка", id: "TO_STOP", color: 'rgb(198,119,0)' },
    { name: "Остановлен", id: "STOPPED", color: 'rgb(206,125,14)' }
];

export const BillingTypes = [
    { name: "Белый биллинг", id: "BILLING_WHITE", color: 'rgb(51,135,0)'},
    { name: "Серый биллинг", id: "BILLING_GREY", color: 'grey'},
    { name: "Серый биллинг без клиентов", id: "BILLING_GREY_WITHOUT_CLIENTS", color: 'rgb(205,166,0)'}
];



function BillingStatisticDataModal ({url, title, isOpen, onClose }) {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { loaded, data, error } = AxiosGet(url, "GET", null, enqueueSnackbar);

    let res = CustomContentForLoadAndErrorWithContent(loaded,error, LoaderContent(isOpen, onClose, title));
    if(res !== null)
        return res;
    else {
        // debugger;
        return (
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>{"Статистка по биллингу"}</DialogTitle>
                <DialogContent sx={{p: '1rem !important'}}>
                    {loaded ? (
                            data.map((stat, index) => (
                                <>
                                    <TableContainer component={Paper} style={{paddingBottom: "1rem"}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align={"center"} sx={{
                                                        backgroundColor: theme.palette.primary.main,
                                                        color: theme.palette.primary.light,
                                                        fontWeight: 800
                                                    }}>Кампания</TableCell>
                                                    <TableCell align={"center"} sx={{
                                                        backgroundColor: theme.palette.primary.main,
                                                        color: theme.palette.primary.light,
                                                        fontWeight: 800
                                                    }}>Кол-во транзакций в реестре до начала биллинга</TableCell>
                                                    <TableCell align={"center"} sx={{
                                                        backgroundColor: theme.palette.primary.main,
                                                        color: theme.palette.primary.light,
                                                        fontWeight: 800
                                                    }}>Кол-во транзакций попавших под биллинг</TableCell>
                                                    <TableCell align={"center"} sx={{
                                                        backgroundColor: theme.palette.primary.main,
                                                        color: theme.palette.primary.light,
                                                        fontWeight: 800
                                                    }}>Кол-во дубликатов</TableCell>
                                                    <TableCell align={"center"} sx={{
                                                        backgroundColor: theme.palette.primary.main,
                                                        color: theme.palette.primary.light,
                                                        fontWeight: 800
                                                    }}>Количество возвратов</TableCell>
                                                    <TableCell align={"center"} sx={{
                                                        backgroundColor: theme.palette.primary.main,
                                                        color: theme.palette.primary.light,
                                                        fontWeight: 800
                                                    }}>Количество новых транзакций в реестре</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {stat?.processStatisticDataList?.map((stat, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell align={"center"}>{stat.rtmmId}</TableCell>
                                                        <TableCell align={"center"}>{stat.countExistedRows}</TableCell>
                                                        <TableCell align={"center"}>{stat.countBilledRows}</TableCell>
                                                        <TableCell align={"center"}>{stat.countDuplicatesRows}</TableCell>
                                                        <TableCell align={"center"}>{stat.countRefundsRows}</TableCell>
                                                        <TableCell align={"center"}>{stat.countPaymentRows}</TableCell>
                                                    </TableRow>
                                                ))}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <TableContainer component={Paper} style={{paddingBottom: "1rem"}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.light, fontWeight: 800 }}>Кампания</TableCell>
                                                    <TableCell align="center" sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.light, fontWeight: 800 }}>Сумма</TableCell>
                                                    <TableCell align="center" sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.light, fontWeight: 800 }}>Кэшбэк</TableCell>
                                                    <TableCell align="center" sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.light, fontWeight: 800 }}>Комиссия</TableCell>
                                                    <TableCell align="center" sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.light, fontWeight: 800 }}>Аудитория</TableCell>
                                                    <TableCell align="center" sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.light, fontWeight: 800 }}>Клиенты</TableCell>
                                                    <TableCell align="center" sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.light, fontWeight: 800 }}>Транзакции</TableCell>
                                                    <TableCell align="center" sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.light, fontWeight: 800 }}>Процент кэшбэка</TableCell>
                                                    <TableCell align="center" sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.light, fontWeight: 800 }}>Процент комиссии</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {stat?.fullStatisticDataList?.map((stat, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell align="center">{stat.rtmmId}</TableCell>
                                                        <TableCell align="center">{stat.sum}</TableCell>
                                                        <TableCell align="center">{stat.cashback}</TableCell>
                                                        <TableCell align="center">{stat.commision}</TableCell>
                                                        <TableCell align="center">{stat.auditory}</TableCell>
                                                        <TableCell align="center">{stat.clients}</TableCell>
                                                        <TableCell align="center">{stat.transactions}</TableCell>
                                                        <TableCell align="center">{stat.cashbackPercent}</TableCell>
                                                        <TableCell align="center">{stat.comissionPercent}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                                ))
                    ) : (
                        <></>
                    )}
                </DialogContent>
                {/*<DialogActions>*/}
                {/*    <Button onClick={onClose}>Close</Button>*/}
                {/*</DialogActions>*/}
            </Dialog>
        );
    }
}

function BankFilter() {
    const dispatch = useDispatch();
    const billingList = useSelector((state) => state.billings);
    const [selectedTypeId, setSelectedTypeIdLocal] = useState(null);

    // debugger;
    let groupsByBank;
    try {
        groupsByBank = groupBy(billingList, "bank");
    }catch (e){

    }

    let names = [];

    if(groupsByBank && Object.entries(groupsByBank).length > 0)
        names = Object.entries(groupsByBank).map(g => g[0]);

    const handleSelect = (id) => {

        setSelectedTypeIdLocal(id);
        dispatch(setSelectedTypeId(id));
    };

    let banksList = Banks.sort((a, b) => a.name.localeCompare(b.name));


    let bankItems = Array.from(banksList, (item) => ({
        id: item.id,
        title: item.name,
        icon: () => <IconBuildingBank stroke={2} color={item.id ? getColorBankByKeyword(item.id) : 'grey'}/>
    }));

    if(bankItems.length > 0)
        return (
            <TypeSwitchBar title={'Фильтр. Банк'} items={bankItems} valueSetter={handleSelect} selectedId={selectedTypeId} gridSize={"SMALL"}/>
        );
    else
        return <></>;
}

function BillingTypeFilter() {
    const dispatch = useDispatch();
    const [selectedBillingType, setSelectedBillingTypeLocal] = useState(null);

    const handleSelect = (id) => {
        setSelectedBillingTypeLocal(id);
        dispatch(setSelectedBillingType(id));
    };

// Генерация компонентов иконок с заданным цветом
    let items = BillingTypes.map(item => ({
        ...item,
        icon: () => <IconFile stroke={2} color={item.id ? getColorBillingTypeByKeyword(item.id) : 'grey'}/>
    }));


    // debugger;
    let billingTypeItems = Array.from(items, (item) => ({
        id: item.id,
        title: item.name,
        icon: item.icon
    }));

    if (billingTypeItems.length > 0)
        return (
            <TypeSwitchBar title={'Фильтр. Тип'} items={billingTypeItems} valueSetter={handleSelect}
                           selectedId={selectedBillingType} gridSize={"SMALL"}/>
        );
    else
        return <></>;
}

export function getColorBillingStatusByKeyword(status) {
    const statusDetails = BillingStatuses.find(s => s.id === status);
    return statusDetails ? statusDetails.color : 'rgb(128, 128, 128)'; // Возвращаем серый цвет, если статус не найден
}

function getColorBillingTypeByKeyword(keyword) {
    if (keyword) {
        const fileType = BillingTypes.find(type => keyword.includes(type.id));
        return fileType ? fileType.color : 'grey'; // Возвращаем цвет найденного типа или серый, если ничего не найдено
    }
    return 'grey'; // Возвращаем серый цвет, если ключевое слово отсутствует
}


function BillingStatusFilter() {
    const dispatch = useDispatch();
    const [selectedStatus, setSelectedStatusLocal] = useState(null);
    const selectedBankFilter = useSelector((state) => state.bankFilter.selectedTypeId);
    const selectedBillingTypeFilter = useSelector((state) => state.billingTypeFilter.selectedBillingType);
    const billingList = useSelector((state) => state.billings);

    let filteredBillingList = billingList;
    if (selectedBankFilter) {
        filteredBillingList = billingList.filter(j => j.bank === selectedBankFilter);
    }

    if (selectedBillingTypeFilter) {
        if (filteredBillingList.filter(j => j.type === selectedBillingTypeFilter).length > 0)
            filteredBillingList = filteredBillingList.filter(j => j.type === selectedBillingTypeFilter);
    }


    const handleSelect = (id) => {
        setSelectedStatusLocal(id);
        dispatch(setSelectedBillingStatus(id));
    };

    let statusesForBillings = BillingStatuses.filter(s => filteredBillingList.map(f => f.status).includes(s.id));

    let items = statusesForBillings.map(item => ({
        ...item,
        icon: () => <IconClipboardTypography stroke={2} color={item.id ? getColorBillingStatusByKeyword(item.id) : 'grey'}/>
    }));

    // debugger;
    let billingStatusItems = Array.from(items, (item) => ({
        id: item.id,
        title: item.name,
        icon: item.icon
    }));

    if (billingStatusItems.length > 0)
        return (
            <TypeSwitchBar title={'Фильтр. Статус'} items={billingStatusItems} valueSetter={handleSelect}
                           selectedId={selectedStatus} gridSize={"MIDDLE"}/>
        );
    else
        return <></>;
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


export default BillingManagerView;

import { configureStore } from '@reduxjs/toolkit';
import {bankFilterSlice} from "../reducers/bankFilterSlice";
import {jobsSlice} from "../reducers/jobsSlice";
import {fileTypeFilterSlice} from "../reducers/fileTypeFilterSlice";
import {fileStatusFilterSlice} from "../reducers/fileStatusFilterSlice";
import {billingTypeFilterSlice} from "../reducers/billingTypeFilterSlice";
import {filesSlice} from "../reducers/fileSlice";
import {modalFileUploadStatisticSlice} from "../reducers/modalFileUploadStatisticSlice";
import {billingsSlice} from "../reducers/billingSlice";
import {modalBillingProcessStatisticSlice} from "../reducers/modalBillingProcessStatisticSlice";
import {websocketSlice} from "../reducers/websocketSlice";
import {billingStatusFilterSlice} from "../reducers/billingStatusFilterSlice";
import {jobRequestSlice} from "../reducers/jobRequestSlice";

import priorityFileSlice from "../reducers/priorityFileSlice";
import frameDataVersionSlice from "../reducers/frameDataVersionSlice";


export const store = configureStore({
    reducer: {
        bankFilter: bankFilterSlice.reducer,
        jobs: jobsSlice.reducer,
        fileTypeFilter: fileTypeFilterSlice.reducer,
        fileStatusFilter: fileStatusFilterSlice.reducer,
        billingTypeFilter: billingTypeFilterSlice.reducer,
        files: filesSlice.reducer,
        billings: billingsSlice.reducer,
        billingStatusFilter: billingStatusFilterSlice.reducer,
        modalFileUploadStatistic: modalFileUploadStatisticSlice.reducer,
        modalBillingProcessStatistic: modalBillingProcessStatisticSlice.reducer,
        websocket: websocketSlice.reducer,
        priorityFiles: priorityFileSlice.reducer, // Добавляем в reducer
        frameDataVersions: frameDataVersionSlice.reducer, // Добавляем в reducer
        jobRequest: jobRequestSlice.reducer, // Добавляем в reducer
    }
});

import {useSnackbar} from "notistack";
import UseToken from "../../auth/UseToken";
import React, {useEffect, useState} from "react";
import {AxiosPostWithoutState} from "../../axios/AxiosPost";
import {jobServiceApiUrl, processServiceApiUrl} from "../../schema/Environment";
import DashboardCard from "../../components/shared/DashboardCard";
import {Badge, Button, Stack, Tooltip, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MultiSelectField from "../../components/inputs/MultiSelectField";
import {DatePickerBase} from "../../components/inputs/CustomDatePicker";
import {addJobList, addProcessInfoByList} from "./reducers/jobsSlice";
import {useDispatch, useSelector} from "react-redux";
import {AxiosGetWithoutState} from "../../axios/AxiosGet";
import {SelectFieldBase} from "../../components/inputs/SelectField";
import StringField from "../../components/inputs/StringField";
import {setJobData, updateJobParam} from "./reducers/jobRequestSlice";


export const ParamBox = ({item}) => (
    <Box
        sx={{
            width: '100%',
            border: '1px solid #d6d6d6bf',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem'
        }}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {item.required ? (
                            <Tooltip title="Обязательный параметр">
                                <Badge color="error" variant="dot" sx={{ width: 100 }}>
                                    <Typography variant="h6">Название</Typography>
                                </Badge>
                            </Tooltip>
                        ) : (
                            <Typography variant="h6">Название</Typography>
                        )}
                        <Typography variant="body1" color="textSecondary">
                            {item.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6">Тип</Typography>
                        <Typography variant="body1" color="textSecondary">
                            {translateFileType(item.jobParamType)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Описание</Typography>
                <Typography variant="body1" color="textPrimary">
                    {item.description}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {item.jobParamType === 'VALUE_LIST' ? (
                    <MultiSelectField paramId={item.id} />
                ) :
                    item.jobParamType === 'STRING_FROM_VALUE_LIST' ? (
                        <SelectFieldBase paramId={item.id} paramsSorted={item.paramsSorted} />
                        )
                    :
                        item.jobParamType === 'STRING' ? (
                                <StringField paramId={item.id} itemList={item.jobParamValues} />
                            )
                            :
                    (item.jobParamType === 'DATE' ? (
                        <DatePickerBase  paramId={item.id}/>
                    ) :
                    (
                        <></>
                    ))}

            </Grid>
        </Grid>
    </Box>
);



export const JobLauncherBox = React.memo(({job, url, customHandleClick}) => {
    const { enqueueSnackbar } = useSnackbar();
    const {currentToken, setToken} = UseToken();
    const dispatch = useDispatch();

    // Получаем данные из Redux Store
    const jobData = useSelector((state) => state.jobRequest.jobData);

    // Синхронизация переданного `job` с Redux Store при изменении `job`
    useEffect(() => {
        if (job) {
            dispatch(setJobData({
                id: job.id,
                description: job.description,
                jobParams: job.jobParams,
            }));
        }
    }, [job, dispatch]);

    if (!job) return <></>;


    const sortedValues = [...(jobData?.jobParams || [])].sort((a, b) => a.id - b.id);

    const handleClick = () => {
        if(customHandleClick)
            customHandleClick(jobData);
        else
            defaultHandleClick(jobData);
    }

    const defaultHandleClick = (jobData) => {
        const launchParamMap = {};
        // debugger;
        jobData.jobParams.filter(item => item.value !== null && item.value !== "null" && item.value !== undefined && item.value !== "undefined" ).forEach(obj => {
            launchParamMap[obj.name] = obj.value;
        });

        AxiosPostWithoutState(url,{jobId: jobData.id}, launchParamMap,  currentToken).then(({loaded, error, data}) => {

            if(error){
                enqueueSnackbar(error, {variant: "error"});
            }
            else {
                enqueueSnackbar("Отправлена на выполнение", {variant: "success"});
                dispatch(addJobList(data));

                const processIdListQuery = data.map(job => encodeURIComponent(job.processId)).join(',');

                // Добавляем инвормацию о выполнении задачи
                AxiosGetWithoutState(processServiceApiUrl + "/process/find/byProcessIdList", "GET", { processIds: processIdListQuery }, currentToken).then(({loaded, error, data}) => {

                    if(error){
                        enqueueSnackbar(error, {variant: "error"});
                        console.log("Не удалось получить статусы процессов: \n" + error);
                    }
                    else {
                        dispatch(addProcessInfoByList(data));
                    }
                }).catch(error => {
                    enqueueSnackbar("Не удалось получить статусы процессов", {variant: "error"});
                    console.log("Не удалось получить статусы процессов: \n" + error);
                });
            }
        }).catch(error => {
            enqueueSnackbar("Непредвиденная ошибка при постановке задачи", {variant: "error"});
        });
    }

    return(
        <DashboardCard title="Параметры">
            <Typography variant="body1" color="textPrimary" sx={{ mb: '1rem' }}>
                {jobData?.description}
            </Typography>
            <Grid container spacing={2}>
                {sortedValues.map((item, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                        <ParamBox jobId={jobData.id} item={item}/>
                    </Grid>
                ))}
            </Grid>
            <Stack spacing={1} direction="row" justifyContent="flex-end">
                <></>
                <Button variant="contained" color="primary" sx={{mt: '1rem'}} onClick={handleClick}>
                    Запустить
                </Button>
            </Stack>
        </DashboardCard>
    )
});

function translateFileType(fileType) {
    const translations = {
        "STRING_FROM_VALUE_LIST": "Значение из списка",
        "STRING": "Строка",
        "VALUE_LIST": "Список значений",
        "DATE": "Дата"
    };

    // Возвращаем перевод для данного типа файла, или сам тип, если перевод не найден
    return translations[fileType] || fileType;
}

export default JobLauncherBox;
